import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const ReleaseNotesDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 90%;
  margin-top: ${getSpacing(4)};
`;

export const ReleaseNotesImageContainer = styled.img`
  width: 100%;
  height: 100%;
  zoom: 1;
`;
