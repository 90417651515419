import { ArrowIcon } from 'assets/icons/ArrowIcon';
import { useIsMobile } from 'breakpoints';
import { IconButton } from 'components/IconButton/IconButton';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserRoleLabel } from 'redux/User/selectors';
import { AuthenticatedUser } from 'services/authentication/useUserInformation';

import {
  USER_CARD_POPOVER_MENU_ARIA_CONTROLS,
  UserCardPopover,
} from '../UserCardPopover/UserCardPopover';
import {
  UserCardContainer,
  UserInformationContainer,
  UserMobileAvatar,
  UserNameContainer,
  UserRoleContainer,
} from './UserCard.style';

type UserCardProps = {
  user: AuthenticatedUser;
};

export const UserCard = ({ user }: UserCardProps): JSX.Element => {
  const isMobile = useIsMobile();
  const userRoleLabel = useSelector(getUserRoleLabel);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const displayedName = `${user.givenName} ${user.familyName}`;

  const handleToggle = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <UserCardContainer
      aria-controls={isPopoverOpen ? USER_CARD_POPOVER_MENU_ARIA_CONTROLS : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
    >
      {!isMobile && (
        <UserInformationContainer ref={anchorRef}>
          <UserNameContainer>{displayedName}</UserNameContainer>
          <UserRoleContainer>{userRoleLabel ?? ''}</UserRoleContainer>
        </UserInformationContainer>
      )}
      <IconButton displayStyle="grey" size="small">
        {isMobile ? (
          <UserMobileAvatar ref={anchorRef}>
            {displayedName.charAt(0).toLocaleUpperCase()}
          </UserMobileAvatar>
        ) : (
          <ArrowIcon
            widthInPx={14}
            heightInPx={9}
            arrowDirection={isPopoverOpen ? 'top' : 'down'}
          />
        )}
      </IconButton>
      <UserCardPopover
        isOpen={isPopoverOpen}
        close={() => setIsPopoverOpen(false)}
        anchorRef={anchorRef}
      />
    </UserCardContainer>
  );
};
