import { FunctionComponent, LazyExoticComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';

type LazyRouteProps<T extends LazyExoticComponent<FunctionComponent>> = Omit<
  RouteProps,
  'component'
> & {
  component?: T;
};

export function LazyRoute<T extends LazyExoticComponent<FunctionComponent>>(
  props: LazyRouteProps<T>,
) {
  const { component: Component, ...rest } = props;

  return <Route {...rest} component={Component} />;
}
