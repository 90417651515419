import { css } from 'styled-components';

/**
 * This file is here to ensure UI consistency
 * You **MUST** sync with your designer at the start of the project to check
 * what colors/typos/grid unit you are going to use, and stick to it.
 */

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 * and elements with fixed width/height
 */
const SPACING_UNIT = 8;
const MEASUREMENT_UNIT = 'px';
export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

export const getSizeInPx = (size: number) => `${size}px`;

/**
 * Use this palette in your components
 * If a new color is in the mockups, check with the designer
 * that the project really need a new color and add it here.
 * As to naming, the best name is the name used by the designer
 */
export const colorPalette = {
  // White variations
  white: '#FFFFFF',
  backgroundMain: '#F4F6FA',
  // Grey variations
  grey: '#121A21',
  grey_100: '#E9E9E9',
  grey_300: '#EDF1F7',
  grey_400: '#C5CEE0',
  grey_500: '#8F9BB3',
  grey_600: '#636E89',
  greyMain: '#212121',
  neutral_medium: '#5B80A4',
  neutral_light: '#9CB3C9',
  neutral_ultralight: '#EFF2F6',
  // Primary
  primary: window?._env_?.primaryColor ?? '#004B55',
  // Green variations
  status_success: '#5CC689',
  status_success_light: '#EBFBF1',
  // Orange variations
  get secondary_100() {
    return `${this.primary}10`;
  },
  get secondary_300() {
    return `${this.primary}45`;
  },
  get secondary_500() {
    return `${this.primary}85`;
  },
  get secondary_600() {
    return this.primary;
  },
  status_warning_light: '#FFFAE5',
  warning_highlight: '#FFE4CC',
  warning_light: '#FFAF66',
  warning_default: '#FF7A00',
  // Blue variations
  blue_050: 'rgba(0, 122, 255, 0.05)',
  blue_100: '#E3ECFC',
  blue_200: '#C7D4F3',
  blue_400: '#5075CA',
  blue_500: '#007AFF',
  blue_600: '#123EA5',
  blueMain: '#001D4A',
  policeBlue: '#364D63',
  vistaBlue: '#7D91E8',
  paleLavender: '#D4DAF7',
  // Red variations
  status_error: '#E9263D',
  status_error_light: '#DF5162',
  red_100: '#FDE6EE',
  hot_pink: '#F372B7',
  // Yellow variations
  light_yellow: '#FFE885',
  // Black variations
  black: '#000000',
  black_dark_grey: '#000000de',
  black_grey: '#00000033',
  eerie_black: '#121A21',
};

export const fontFamily = {
  main: `'Open Sans', 'Lato', 'Helvetica', 'Arial', sans-serif`,
};

export const fontSize = {
  p1: '16px',
  p2: '14px',
  p3: '12px',
  title_xxxlarge: '46px',
  title_xxlarge: '36px',
  title_xlarge: '32px',
  title_large: '30px',
  title_medium: '26px',
  title_small: '22px',
  title_xsmall: '18px',
};

export const typography = {
  p1_regular: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p1};
    font-weight: normal;
    line-height: 1.5;
  `,
  p1_bold: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p1};
    font-weight: bold;
    line-height: 1.5;
  `,
  p1_italic: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p1};
    font-weight: normal;
    font-style: italic;
    line-height: 1.5;
  `,
  p2_regular: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p2};
    font-weight: normal;
    line-height: 1.5;
  `,
  p2_bold: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p2};
    font-weight: bold;
    line-height: 1.5;
  `,
  p2_italic: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p2};
    font-weight: normal;
    font-style: italic;
    line-height: 1.5;
  `,
  p3_regular: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p3};
    font-weight: normal;
    line-height: 1.5;
  `,
  p3_bold: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p3};
    font-weight: bold;
    line-height: 1.5;
  `,
  p3_italic: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p3};
    font-weight: normal;
    font-style: italic;
    line-height: 1.5;
  `,
  title_xxxlarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_xxxlarge};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_xxlarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_xxlarge};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_xlarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_xlarge};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_large: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_large};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_medium: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_medium};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_small: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_small};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_xsmall: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_xsmall};
    font-weight: bold;
    line-height: 1.3;
  `,
};

export const frameHeight = {
  header: '80px',
  loader: '32px',
  buttonInnerSize: 27,
  tabHeader: '56px',
  tabHeaderMargin: getSpacing(2),
};
