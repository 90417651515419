import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useAppDispatch } from '../../../redux/hooks';
import { getNumberOfUnreadMessages, getSelectedPermission } from '../../../redux/User/selectors';
import { setNumberOfUnreadMessages } from '../../../redux/User/slice';
import { getNumberOfUnreadMessageForPermission } from '../../../services/api/clientRequests/client';
import { ExchangeIcon } from '../ExchangeIcon';
import { IconPossiblyLateralProps } from '../types';
import { NotificationCounterBubble, NotificationIconContainer } from './NotificationIcon.style';

export const ClientRequestNotificationIcon = ({
  widthInPx,
  heightInPx,
  isLateralMenuIcon,
  color,
}: IconPossiblyLateralProps): JSX.Element => {
  const selectedPermission = useSelector(getSelectedPermission);
  const numberOfUnreadMessages = useSelector(getNumberOfUnreadMessages);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const fetchCountUnreadClientRequests = useCallback(async () => {
    if (selectedPermission && isLateralMenuIcon) {
      const countClientRequestsUnreadForPermission = await getNumberOfUnreadMessageForPermission(
        selectedPermission.id,
      );

      dispatch(
        setNumberOfUnreadMessages({
          numberOfUnreadMessages:
            countClientRequestsUnreadForPermission.countUnreadClientRequestViews,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLateralMenuIcon, selectedPermission, location]);

  useEffect(() => {
    fetchCountUnreadClientRequests();
  }, [fetchCountUnreadClientRequests]);

  const numberOfMessagesToDisplay = useMemo(() => {
    const MAX_NUMBER_OF_MESSAGES_DISPLAYED = 9;

    return numberOfUnreadMessages > MAX_NUMBER_OF_MESSAGES_DISPLAYED
      ? `>${MAX_NUMBER_OF_MESSAGES_DISPLAYED}`
      : numberOfUnreadMessages;
  }, [numberOfUnreadMessages]);

  return (
    <NotificationIconContainer>
      <ExchangeIcon widthInPx={widthInPx} heightInPx={heightInPx} color={color}></ExchangeIcon>
      {numberOfUnreadMessages > 0 && (
        <NotificationCounterBubble isLateralMenuIcon={isLateralMenuIcon}>
          {numberOfMessagesToDisplay}
        </NotificationCounterBubble>
      )}
    </NotificationIconContainer>
  );
};
