// Configuration of analytics provider (Matomo, Google Analytics)
export type AnalyticsProviderEventConfig = {
  action: string;
  label: string;
  category: string;
};

export const ANALYTICS_PROVIDER_CATEGORIES = {
  DELIVERY_NOTES: 'Delivery notes',
  INVOICES: 'Invoices',
  CLIENT_COMPANY: 'Client company',
  CLIENT_COMPANY_USERS: 'Client company users',
  SITE_DOCUMENTS: 'Site documents',
  CLIENT_COMPANIES: 'Client companies',
  CLIENT_REQUESTS: 'Client requests',
  BUSINESS_ADMIN_CLIENT_REQUESTS: 'Business Admin Client requests',
  BUSINESS_ADMIN_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
    'Business Admin Inert Waste Application for prior acceptance',
  BUSINESS_ADMIN_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EVALUATION:
    'Business Admin Inert Waste Application for prior acceptance evaluation',
  CLIENT_REQUEST_DOCUMENTS: 'Client request documents',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE: 'Inert waste application for prior acceptance',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PRODUCER:
    'Inert waste application for prior acceptance (producer)',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER:
    'Inert waste application for prior acceptance (ext_user)',
  HELP_AND_CONTACT: 'Help and contact',
  GDPR: 'GDPR',
  HOME: 'Home',
  SITE_LOCATIONS: 'Site Locations',
  SUBSCRIPTION_REQUEST: 'Subscription request',
  BUSINESS_REFERENTS: 'Business Referents',
  REFERENTS_MANAGEMENT: 'Referents Management',
  MOBILE_SUPPORT: 'Mobile support',
};

export const ANALYTICS_PROVIDER_LABELS = {
  PREVIEW_BUTTON_CLICK: 'Click on preview button',
  DOWNLOAD_BUTTON_CLICK: 'Click on download button',
  PAGE_VIEW: 'Page view',
  ACTION_SUCCESS: 'Action success',
  CLICK: 'Click',
  REDIRECT_BUTTON_CLICK: 'Click on redirect button',
  CHOICE_SELECTION: 'Choice selection',
};

export const ANALYTICS_PROVIDER_ACTIONS = {
  //Home
  HOME_PAGE_VIEW: 'Home_view',

  //DeliveryNotes
  DELIVERY_NOTES_PAGE_VIEW: 'Delivery_note_list',
  DELIVERY_NOTES_PREVIEW_BUTTON_CLICK: 'Delivery_note_visu_pdf',
  DELIVERY_NOTES_DOWNLOAD_BUTTON_CLICK: 'Delivery_note_dl_pdf',
  DELIVERY_NOTES_EXPORT_BUTTON_CLICK: 'Customer_DNExport',
  DELIVERY_NOTES_BULK_DOWNLOAD_BUTTON_CLICK: 'Customer_DNBulkDownload',
  DELIVERY_NOTES_FILTERS: 'DeliveryNote_Filter_on',

  //Invoices
  INVOICES_PAGE_VIEW: 'Invoice_list',
  INVOICES_PREVIEW_BUTTON_CLICK: 'Invoice_visu_pdf',
  INVOICES_DOWNLOAD_BUTTON_CLICK: 'Invoice_dl_pdf',
  INVOICES_EXPORT_BUTTON_CLICK: 'Invoice_Export',
  INVOICES_BULK_DOWNLOAD_BUTTON_CLICK: 'Invoice_BulkDownload',
  INVOICES_FILTERS: 'Invoice_Filter_on',
  INVOICES_GO_TO_LINKED_DELIVERY_NOTE_BUTTON_CLICK: 'Invoice_GoToLinkedDN',

  //ClientCompany
  CLIENT_COMPANY_PAGE_VIEW: 'Customer_view_detail',
  CLIENT_COMPANY_NEW_REQUEST_SENT: 'Newrequest_send',
  CLIENT_COMPANY_NEW_REQUEST_MESSAGE_SENT: 'Request_newmessage_sent',
  CLIENT_COMPANY_USERS_VIEW: 'Customer_view_users',
  CLIENT_COMPANY_BUSINESS_REFERENTS_VIEW: 'Business_referents_view',

  //BusinessReferents
  BUSINESS_REFERENTS_DEACTIVATE_CONFIRM: 'Business_referents_deactivate_confirm',

  //ClientCompanyUsers
  CLIENT_COMPANY_USERS_PAGE_VIEW: 'CustomerUsers_view',
  CLIENT_COMPANY_USERS_CUSTOMER_USER_INVITE: 'CustomerUser_Invite',
  CLIENT_COMPANY_USERS_CUSTOMER_USER_DELETE: 'CustomerUser_delete',

  //ClientCompanies
  CLIENT_COMPANIES_PAGE_VIEW: 'Customer_list',
  CLIENT_COMPANIES_FILTERS: 'Clientcompanies_filters',
  CLIENT_COMPANIES_CUSTOMER_INVITE_CANCEL: 'Customer_invite_cancel',
  CLIENT_COMPANIES_CUSTOMER_INVITE_CREATION: 'Customer_invite_creation',

  //BusinessAdminClientRequests
  BUSINESS_ADMIN_CLIENT_REQUESTS_PAGE_VIEW: 'BusinessAdminClientrequests_list',

  //BusinessAdminInertWasteApplication
  BUSINESS_ADMIN_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PAGE_VIEW: 'DAP_businessadmin_list',

  //BusinessReferents
  BUSINESS_REFERENTS_PAGE_VIEW: 'BusinessReferents-list',

  //ClientRequests
  CLIENT_REQUESTS_PAGE_VIEW: 'Clientrequests_list',
  CLIENT_REQUESTS_FILTERS: 'Clientrequests_filters',

  //ClientRequestDocuments
  CLIENT_REQUEST_DOCUMENTS_PAGE_VIEW: 'Clientrequestdocuments_list',

  //InertWasteApplicationForPriorAcceptance
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PAGE_VIEW: 'DAP_list',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_FILTERS: 'DAP_filters',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EVALUATION_CLICK: 'DAP_evaluation_click',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EVALUATION_CONFIRM: 'DAP_evaluation_confirm',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_SHARE_BUTTON: 'DAP_share_button',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_SEE_DETAILS_CLICK: 'DAP_see_details_click',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_SEE_PDF: 'DAP_see_pdf',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_DOWNLOAD_PDF: 'DAP_download_pdf',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_START_NEW_APPLICATION: 'DAP_start_new_application',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PRODUCER_OPEN_FORM: 'DAP_producer_open_form',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PRODUCER_REFUSAL: 'DAP_producer_refusal',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PRODUCER_ACCEPTANCE: 'DAP_producer_acceptance',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_REFUSAL: 'DAP_ext_user_refusal',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_ACCEPTANCE: 'DAP_ext_user_acceptance',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_KNOWN_CLIENT_WITH_ACTIVE_ACCESS:
    'DAP_ext_user_known_client_with_active_access',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_KNOWN_CLIENT_WITH_INACTIVE_ACCESS:
    'DAP_ext_user_known_client_with_inactive_access',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_UNKNOWN_CLIENT:
    'DAP_ext_user_unknown_client',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_LOGIN_ON_CAPTCHA_WARNING:
    'DAP_ext_user_login_on_captcha',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_LOGIN_ON_DAP_FORM:
    'DAP_ext_user_login_on_dap_form',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_CREATE_NEW: 'DAP_ext_user_create_new',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_HELP: 'DAP_ext_user_help',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_CANCEL_IN_CAPTCHA_PAGE:
    'DAP_ext_user_cancel_captcha',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_CONTINUE_IN_CAPTCHA_PAGE:
    'DAP_ext_user_continue_captcha',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EXTERNAL_USER_SIGN: 'DAP_ext_user_sign',

  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_CREATION_IDENTIFICATION_MODE_SITE:
    'DAP_identification_mode_site',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_CREATION_IDENTIFICATION_MODE_WASTE:
    'DAP_identification_mode_waste',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_CREATION_IDENTIFICATION_TYPE_ADDRESS:
    'DAP_identification_type_address',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_CREATION_IDENTIFICATION_TYPE_GPS:
    'DAP_identification_type_gps',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_CREATION_IDENTIFICATION_TYPE_CADASTRE:
    'DAP_identification_type_cadastre',

  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EVALUATION_MISSING_DOCS:
    'DAP_evaluation_missing_docs',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_EVALUATION_DISPLAY_CASIAS_BASIAS:
    'DAP_evaluation_display_casias_basias',

  //SiteDocuments
  SITE_DOCUMENTS_PAGE_VIEW: 'Sitedoc_list',
  SITE_DOCUMENTS_PREVIEW_BUTTON_CLICK: 'Sitedoc_doc_visu',
  SITE_DOCUMENTS_DOWNLOAD_BUTTON_CLICK: 'Sitedoc_doc_dl',
  SITE_DOCUMENTS_DELETE_COMFIRM: 'Sitedoc_doc_delete',
  SITE_DOCUMENTS_PUBLISH: 'Sitedoc_doc_publish',
  SPECIFIC_SITE_DOCUMENTS_PREVIEW_BUTTON_CLICK: 'Sitedoc_site_relateddoc_visu',
  SPECIFIC_SITE_DOCUMENTS_DOWNLOAD_BUTTON_CLICK: 'Sitedoc_site_relateddoc_dl',

  //SiteLocation
  SITE_LOCATIONS_PAGE_VIEW: 'Sitedoc_site_visu',
  SITE_LOCATIONS_DISPLAY_SITE_DOCUMENTS_BUTTON_CLICK: 'Sitedoc_site_relateddoc',
  SITE_LOCATIONS_ADDRESS_SELECTION: 'Sitedoc_site_directionsearch',
  SITE_LOCATIONS_ACTIVITY_SELECTION: 'Sitedoc_site_activityselect',

  //GDPR
  GDPR_DOCUMENT_CLICK: 'Profile_RGPD_PPDPdl',
  GDPR_EMAIL_CLICK: 'Profile_RGPD_email',

  //HelpAndContact
  HELP_AND_CONTACT_EMAIL_CLICK: 'Helpandcontact_email',
  HELP_AND_CONTACT_FORM_CLICK: 'Helpandcontact_form',

  //ShadowMode
  SHADOW_DELIVERY_NOTES_PAGE_VIEW: 'Customer_shadowview_DeliveryNote',
  SHADOW_INVOICES_PAGE_VIEW: 'Customer_shadowview_Invoice',
  SHADOW_DELIVERY_NOTES_EXPORT_BUTTON_CLICK: 'Customer_shadowview_DNExport',
  SHADOW_DELIVERY_NOTES_BULK_DOWNLOAD_BUTTON_CLICK: 'Customer_shadowview_DNBulk_Download',

  //SubscriptionRequest
  SUBSCRIPTION_REQUEST_SUBMISSION_SUCCESS: 'NewExternalAccessRequest',

  //ReferentsManagement
  REFERENTS_MANAGEMENT_PAGE_VIEW: 'ReferentsManagement_View',
  BUSINESS_REFERENTS_MANAGEMENT: 'Gestion_RefComm',
  INERT_WASTE_APPLICATION_REFERENTS_MANAGEMENT: 'Gestion_RefDAP',
  BUSINESS_REFERENTS_MANAGEMENT_FILTERS: 'BusinessRefManagement_filters',
  INERT_WASTE_APPLICATION_REFERENTS_FILTERS: 'InertWasteRef_filters',

  // mobile support
  MOBILE_SUPPORT_OK: 'mobile_support_ok',
};
