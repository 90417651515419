import { useMsal } from '@azure/msal-react';

export type AuthenticatedUser = {
  isAuthenticated: true;
  familyName: string;
  givenName: string;
  currentUserEmail: string;
};

type UnAuthenticatedUser = {
  isAuthenticated: false;
};

type UserInformation = AuthenticatedUser | UnAuthenticatedUser;

type IdTokenClaims = { family_name?: string; given_name?: string };

export const useUserInformation = (): UserInformation => {
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  if (accounts.length === 0) {
    return { isAuthenticated: false };
  }
  const account = accounts[0];
  const idTokenClaims = account.idTokenClaims as IdTokenClaims;

  return {
    isAuthenticated: true,
    familyName: idTokenClaims.family_name?.toLowerCase() || '',
    givenName: idTokenClaims.given_name?.toLowerCase() || '',
    currentUserEmail: account.username.toLowerCase(),
  };
};
