import { AuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { ROUTES } from 'routing/routes';
import { AZURE_AD_B2C_REDIRECTION_URI } from 'services/api/constants';
import {
  ACCESS_TOKEN_SCOPES,
  MSAL_EXTRA_QUERY_PARAMETERS,
  MSAL_REDIRECT_PARAMETERS,
  msalInstance,
} from 'services/authentication/msalConfig';
import { logger } from 'services/logs/logger';

export const acquireAccessToken = async () => {
  try {
    const authResultFromPreviousRedirect = await msalInstance.handleRedirectPromise();
    if (authResultFromPreviousRedirect && authResultFromPreviousRedirect.accessToken) {
      return authResultFromPreviousRedirect.accessToken;
    }
  } catch (error) {
    logger.logWarn('Unable to retrieve authResult from previous redirect', error);
  }

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 1) {
    logger.logWarn('Multiple accounts found. Deleting the local storage.');
    window.localStorage.clear();
  }
  if (accounts.length !== 1) {
    await msalInstance.loginRedirect(MSAL_REDIRECT_PARAMETERS);
  }

  const accessTokenRequest = {
    scopes: ACCESS_TOKEN_SCOPES,
    account: accounts[0],
  };
  const accessTokenSilentRequest = {
    ...accessTokenRequest,
    redirectUri: AZURE_AD_B2C_REDIRECTION_URI + ROUTES.B2C_SILENT_REDIRECT,
  };
  const accessTokenRequestWithRedirect = {
    ...accessTokenRequest,
    ...MSAL_EXTRA_QUERY_PARAMETERS,
  };
  try {
    const authResult = await msalInstance.acquireTokenSilent(accessTokenSilentRequest);
    return authResult.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(accessTokenRequestWithRedirect);
    } else if (error instanceof AuthError) {
      logger.logWarn(
        'Error during silent token acquisition',
        error as unknown as Record<string, unknown>,
      );
    } else {
      logger.logError('Error during Azure AD token acquisition', error);
    }
    await msalInstance.loginRedirect(MSAL_REDIRECT_PARAMETERS);
  }
};
