import { TableFilters } from '../filtersKeys';

export enum BusinessReferentsManagementFiltersKeys {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phoneNumber',
}

export const BUSINESS_REFERENTS_MANAGEMENT_FILTERS_INITIAL_VALUES: TableFilters = {
  [BusinessReferentsManagementFiltersKeys.FIRST_NAME]: '',
  [BusinessReferentsManagementFiltersKeys.LAST_NAME]: '',
  [BusinessReferentsManagementFiltersKeys.EMAIL]: '',
  [BusinessReferentsManagementFiltersKeys.PHONE]: '',
};
