import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { Loader } from 'components/Loader/Loader';
import { frameHeight } from 'stylesheet';

import { useButtonStyle } from './Button.style';
import { AdditionalButtonProps } from './Button.types';

export type ButtonProps = MuiButtonProps & AdditionalButtonProps;

export const Button = ({
  displayStyle,
  margins,
  bigPaddings,
  paddings,
  startIcon,
  href,
  disabled,
  onClick,
  type,
  isLoading,
  children,
  justifyContentLeft,
  widthInPx,
  fullWidth,
  customStyle,
}: ButtonProps) => {
  const classes = useButtonStyle({
    margins,
    bigPaddings,
    paddings,
    displayStyle,
    children,
    isLoading,
    justifyContentLeft,
    widthInPx,
    customStyle,
  })();
  return (
    <MuiButton
      classes={classes}
      disabled={disabled}
      href={href}
      onClick={isLoading ? () => null : onClick}
      startIcon={startIcon}
      type={type}
      disableFocusRipple
      fullWidth={fullWidth}
    >
      {children}
      {isLoading && (
        <Loader sizeInPx={frameHeight.buttonInnerSize} color="inherit" position="absolute" />
      )}
    </MuiButton>
  );
};
