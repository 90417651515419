import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const MapMarkerIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 16 20"
    style={{
      width: getSizeInPx(widthInPx ?? 16),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M10.0001 1.66675C6.77508 1.66675 4.16675 4.27508 4.16675 7.50008C4.16675 11.8751 10.0001 18.3334 10.0001 18.3334C10.0001 18.3334 15.8334 11.8751 15.8334 7.50008C15.8334 4.27508 13.2251 1.66675 10.0001 1.66675ZM10.0001 9.58342C8.85008 9.58342 7.91675 8.65008 7.91675 7.50008C7.91675 6.35008 8.85008 5.41675 10.0001 5.41675C11.1501 5.41675 12.0834 6.35008 12.0834 7.50008C12.0834 8.65008 11.1501 9.58342 10.0001 9.58342Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
