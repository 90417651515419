import { useMediaQuery } from '@material-ui/core';

interface Size {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}

const deviceSize: Size = {
  xs: '425px', // for small screen mobile
  sm: '600px', // for mobile screen
  md: '868px', // for tablets
  lg: '1280px', // for laptops
  xl: '1440px', // for desktop / monitors
  xxl: '1920px', // for big screens
};

const mediaQueries =
  (key: keyof typeof deviceSize) =>
  (...styles: (TemplateStringsArray | string)[]) =>
    `@media (max-width: ${deviceSize[key]}) { ${styles} }`;

export const media = {
  xs: mediaQueries('xs'),
  sm: mediaQueries('sm'),
  md: mediaQueries('md'),
  lg: mediaQueries('lg'),
  xl: mediaQueries('xl'),
  xxl: mediaQueries('xxl'),
};

export const useIsMobile = (): boolean => {
  return useMediaQuery(`(max-width:${deviceSize.sm})`, { noSsr: true });
};

export const useIsBigScreen = (): boolean => {
  return useMediaQuery(`(min-width:${deviceSize.xl})`, { noSsr: true });
};
