import {
  ApplicantInformationFormKeys,
  ApplicantType,
} from 'pages/InertWasteApplication/InertWasteApplicationForPriorAcceptance/CreateInertWasteApplicationForPriorAcceptanceFormSteps/ApplicantInformationStep/types';
import { InertWasteApplicationForPriorAcceptanceFormKeys } from 'pages/InertWasteApplication/InertWasteApplicationForPriorAcceptance/InertWasteApplicationForPriorAcceptanceFormKeys';
import { ProducerCompanyDetails } from 'pages/InertWasteApplication/InertWasteApplicationForPriorAcceptance/ProducerInterface/ProducerDetailsAndApplicationSummaryFormSteps/types';
import { InertWasteApplicationForPriorAcceptanceFormInputs } from 'pages/InertWasteApplication/InertWasteApplicationForPriorAcceptance/types';

import { InertWasteApplicationEvaluationFormKeys } from '../../../pages/InertWasteApplication/InertWasteApplicationForPriorAcceptanceTable/types';
import { ApiActionResponse } from '../types';
import { BaseApiUserMetadata } from '../userMetadata/types';

export enum InertWasteApplicationVersion {
  V2 = 'V2',
  V3 = 'V3',
}

export type PartialCreateInertWasteApplicationForPriorAcceptanceBody = {
  clientCompanyId: number;
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance;
  authorUserMetadataId: string;
};

export type PartialCreateInertWasteApplicationForPriorAcceptanceForSessionUserBody = {
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance;
};

export type ApplicationValuesForInertWasteApplicationForPriorAcceptance =
  Partial<InertWasteApplicationForPriorAcceptanceFormInputs>;

export type CreateInertWasteApplicationForPriorAcceptanceBody =
  PartialCreateInertWasteApplicationForPriorAcceptanceBody & {
    legalCorporationId: string;
  };

export type CreateInertWasteApplicationForPriorAcceptanceForSessionUserBody =
  PartialCreateInertWasteApplicationForPriorAcceptanceForSessionUserBody & {
    legalCorporationId: string;
  };

type InertWasteApplicationForPriorAcceptanceStatus = {
  code: InertWasteApplicationForPriorAcceptanceStatusCodes;
  label: string;
};

export type ApiInertWasteApplicationForPriorAcceptanceInList = {
  id: number;
  author: Pick<BaseApiUserMetadata, 'id' | 'fullName'>;
  recipient?: Pick<BaseApiUserMetadata, 'id' | 'fullName'>;
  status: InertWasteApplicationForPriorAcceptanceStatus;
  createdAt: string;
  updatedAt: string;
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance;

  clientCompany: {
    id: number;
    name: string;
  };
  reference: string;
  signedPdfUrl: string;
  site?: {
    name: string;
  };
  shouldRead: boolean;
  version: InertWasteApplicationVersion;
  isExternal: boolean;
};

export function isInertWasteApplicationEvaluated(
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance,
): boolean {
  return !!applicationValues[InertWasteApplicationEvaluationFormKeys.EVALUATION];
}

export function doesInertWasteApplicationInvolveProducer(
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance,
): boolean {
  return (
    applicationValues[InertWasteApplicationForPriorAcceptanceFormKeys.APPLICANT_INFORMATION]?.[
      ApplicantInformationFormKeys.APPLICANT_TYPE
    ] === ApplicantType.APPLICANT
  );
}

export type ApiInertWasteApplicationForPriorAcceptanceForProducerOrExternalEvaluator = {
  status: Pick<InertWasteApplicationForPriorAcceptanceStatus, 'code'>;
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance;
  signedPdfUrl?: string;
};

export enum InertWasteApplicationForPriorAcceptanceTableRowKeys {
  ID = 'id',
  CLIENT_COMPANY_NAME = 'clientCompanyName',
  CLIENT_COMPANY_ID = 'clientCompanyId',
  REFERENCE = 'reference',
  WORKSITE_LABEL = 'worksiteLabel',
  WASTE_LABEL = 'waste',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  AUTHOR_FULL_NAME = 'authorFullName',
  RECIPIENT_FULL_NAME = 'recipientFullName',
  STATUS_CODE = 'statusCode',
  IS_EVALUATION_BUTTON_AVAILABLE = 'isEvaluationButtonAvailable',
  IS_DOWNLOAD_BUTTON_AVAILABLE = 'isDownloadButtonAvailable',
  IS_PREVIEW_BUTTON_AVAILABLE = 'isPreviewButtonAvailable',
  IS_EDIT_DRAFT_BUTTON_AVAILABLE = 'isEditDraftButtonAvailable',
  IS_DELETE_BUTTON_AVAILABLE = 'isDeleteButtonAvailable',
  IS_OPEN_DETAILS_BUTTON_AVAILABLE = 'isOpenDetailsButtonAvailable',
  IS_DUPLICATE_BUTTON_AVAILABLE = 'isDuplicateButtonAvailable',
  IS_SIGN_BUTTON_AVAILABLE_FOR_APPLICANT = 'isSignButtonAvailableForApplicant',
  APPLICATION_VALUES = 'applicationValues',
  SIGNED_PDF_URL = 'signedPdfUrl',
  SITE_NAME = 'siteName',
  SHOULD_READ = 'shouldRead',
  VERSION = 'version',
  MORE_MENU = 'moreMenu',
  ID_HINT_TEXT = 'idHintText',
}

export enum InertWasteApplicationForPriorAcceptanceStatusCodes {
  DRAFT = 'DRAFT',
  /**
   * @deprecated Use 'ONGOING_PRODUCER' or 'ONGOING_APPLICANT' instead
   */
  ONGOING = 'ONGOING',
  ONGOING_PRODUCER = 'ONGOING_PRODUCER',
  ONGOING_APPLICANT = 'ONGOING_APPLICANT',
  TO_BE_EVALUATED_TECHNICAL = 'TO_BE_EVALUATED_TECHNICAL',
  TO_BE_EVALUATED_ZEPHYR = 'TO_BE_EVALUATED_ZEPHYR',
  TO_BE_EVALUATED_SIGNATURE = 'TO_BE_EVALUATED_SIGNATURE',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace InertWasteApplicationForPriorAcceptanceStatusCodes {
  const values = Object.values(InertWasteApplicationForPriorAcceptanceStatusCodes);
  export function isGreaterThan(
    statusCode: InertWasteApplicationForPriorAcceptanceStatusCodes,
    comparedTo: InertWasteApplicationForPriorAcceptanceStatusCodes,
  ): boolean {
    return values.indexOf(statusCode) > values.indexOf(comparedTo);
  }
  export function isGreaterOrEqualTo(
    statusCode: InertWasteApplicationForPriorAcceptanceStatusCodes,
    comparedTo: InertWasteApplicationForPriorAcceptanceStatusCodes,
  ): boolean {
    return values.indexOf(statusCode) >= values.indexOf(comparedTo);
  }
}

export enum AdditionnalInertWasteApplicationForPriorAcceptanceStatusCodesFilters {
  /**
   * Use only to filter by codes starting with 'ONGOING'
   */
  __FILTER_ONGOING = 'ONGOING',
  /**
   * Use only to filter by codes starting with 'TO_BE_EVALUATED'
   */
  __FILTER_TO_BE_EVALUATED = 'TO_BE_EVALUATED',
}

export type InertWasteApplicationForPriorAcceptanceForTable = {
  values: {
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.ID]: number;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.CLIENT_COMPANY_NAME]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.CLIENT_COMPANY_ID]: number;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.REFERENCE]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.WORKSITE_LABEL]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.WASTE_LABEL]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.AUTHOR_FULL_NAME]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.RECIPIENT_FULL_NAME]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.STATUS_CODE]: InertWasteApplicationForPriorAcceptanceStatusCodes;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.CREATED_AT]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.UPDATED_AT]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.IS_EVALUATION_BUTTON_AVAILABLE]: boolean;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.IS_DOWNLOAD_BUTTON_AVAILABLE]: boolean;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.IS_PREVIEW_BUTTON_AVAILABLE]: boolean;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.IS_OPEN_DETAILS_BUTTON_AVAILABLE]: boolean;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.IS_EDIT_DRAFT_BUTTON_AVAILABLE]: boolean;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.IS_DELETE_BUTTON_AVAILABLE]: boolean;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.IS_DUPLICATE_BUTTON_AVAILABLE]: boolean;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.IS_SIGN_BUTTON_AVAILABLE_FOR_APPLICANT]: boolean;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.APPLICATION_VALUES]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.SIGNED_PDF_URL]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.SITE_NAME]: string;
    [InertWasteApplicationForPriorAcceptanceTableRowKeys.VERSION]: InertWasteApplicationVersion;
  };
  isHighlighted: boolean;
};

export type ApiCountInertWasteApplicationsUnread = {
  countUnreadInertWasteApplicationViews: number;
};

export type ApiCreateOrUpdateInertWasteApplicationResponse = {
  message: string;
  id: number;
};

export type RawApiDuplicateInertWasteApplicationResponse = {
  message: string;
  id: number;
  applicationValues: string;
};

export const isRawApiDuplicateInertWasteApplicationResponse = (
  response: ApiActionResponse | RawApiDuplicateInertWasteApplicationResponse,
): response is RawApiDuplicateInertWasteApplicationResponse => {
  return (
    typeof (response as RawApiDuplicateInertWasteApplicationResponse).applicationValues === 'string'
  );
};

type ApiDuplicateInertWasteApplicationResponse = {
  message: string;
  id: number;
  applicationValues: ApplicationValuesForInertWasteApplicationForPriorAcceptance;
};

export const doesApiActionResponseContainInertWasteApplicationId = (
  response: ApiActionResponse | ApiCreateOrUpdateInertWasteApplicationResponse,
): response is ApiCreateOrUpdateInertWasteApplicationResponse => {
  return !!(response as ApiCreateOrUpdateInertWasteApplicationResponse).id;
};

export const doesApiActionResponseContainInertWasteApplicationApplicationValues = (
  response: ApiActionResponse | ApiDuplicateInertWasteApplicationResponse,
): response is ApiDuplicateInertWasteApplicationResponse => {
  return !!(response as ApiDuplicateInertWasteApplicationResponse).applicationValues;
};

export type ApiSearchCompanyBySiretResponse = '' | ProducerCompanyDetails;

export type ApiActionResponseJobImport = {
  message: string;
  zephyrJobId: number;
};

export const isApiActionResponseJobImport = (
  response: ApiActionResponse | ApiActionResponseJobImport,
): response is ApiActionResponseJobImport => {
  return !!(response as ApiActionResponseJobImport).zephyrJobId;
};
