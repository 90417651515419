export enum TableKey {
  CLIENT_COMPANIES = 'clientCompanies',
  USER_CLIENT_COMPANIES = 'userClientCompanies',
  BUSINESS_ADMIN_CLIENT_REQUESTS = 'businessAdminClientRequests',
  CLIENT_REQUESTS = 'clientRequests',
  DELIVERY_NOTES = 'deliveryNotes',
  INVOICES = 'invoices',
  SITE_DOCUMENTS = 'siteDocuments',
  SPECIFIC_SITE_DOCUMENTS = 'specificSiteDocuments',
  SPECIFIC_CLIENT_REQUEST_DOCUMENTS = 'specificClientRequestDocuments',
  CLIENT_COMPANY_USERS = 'clientCompanyUsers',
  CLIENT_COMPANY_BUSINESS_REFERENTS = 'clientCompanyBusinessReferents',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE = 'inertWasteApplicationForPriorAcceptance',
  BUSINESS_REFERENTS_MANAGEMENT = 'businessReferentsManagement',
  INERT_WASTE_APPLICATION_REFERENTS = 'inertWasteApplicationReferents',
}
