import { Divider } from '@material-ui/core';
import { HelpAndContactIcon } from 'assets/icons/HelpAndContactIcon';
import { useIsMobile } from 'breakpoints';
import { HelpAndContactSidePanel } from 'components/HelpAndContactSidePanel/HelpAndContactSidePanel';
import { useMenuPageConfigs } from 'hooks/useMenuPageConfigs/useMenuPageConfigs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserApplication, UserApplication } from 'redux/User/selectors';
import { useAuthorizationService } from 'redux/User/useAuthorizationService';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import { useMenuDivider } from './LateralMenu.style';
import { LateralMenuButton, LateralMenuButtonProps } from './LateralMenuButton/LateralMenuButton';
import { LateralMenuLink } from './LateralMenuLink/LateralMenuLink';

type MenuItemsProps = {
  onCloseMenu?: () => void;
};

export const MenuItems = ({ onCloseMenu }: MenuItemsProps): JSX.Element => {
  const classes = useMenuDivider();
  const isMobile = useIsMobile();
  const menuIntl = useIntlByKey('menu');
  const userApplication = useSelector(getUserApplication);
  const menuPageConfigs = useMenuPageConfigs();

  const [isHelpAndContactSidePanelOpened, setIsHelpAndContactSidePanelOpened] = useState(false);

  const menuButtons: LateralMenuButtonProps[] = [
    {
      label: menuIntl('help-and-contact'),
      icon: <HelpAndContactIcon widthInPx={20} heightInPx={20} />,
      onButtonClick: () => setIsHelpAndContactSidePanelOpened(true),
    },
  ];

  const byApplicationsAndByRoles = useAuthorizationService();

  return (
    <>
      {userApplication !== UserApplication.NO_ACCESS &&
        menuPageConfigs
          .filter(byApplicationsAndByRoles)
          .map((menuPageConfig) => (
            <LateralMenuLink
              key={menuPageConfig.lateralMenuLinkConfig.title}
              path={menuPageConfig.linkPath ?? menuPageConfig.path}
              search={menuPageConfig.search}
              title={menuPageConfig.lateralMenuLinkConfig.title}
              icon={menuPageConfig.icon}
              analyticsProviderEventConfig={menuPageConfig.analyticsProviderEventConfig}
              onCloseMenu={onCloseMenu}
            />
          ))}

      {isMobile && <Divider classes={classes} />}

      {menuButtons.map((menuButton) => (
        <LateralMenuButton
          key={menuButton.label}
          label={menuButton.label}
          icon={menuButton.icon}
          onButtonClick={menuButton.onButtonClick}
        />
      ))}

      <HelpAndContactSidePanel
        onClose={() => setIsHelpAndContactSidePanelOpened(false)}
        isOpen={isHelpAndContactSidePanelOpened}
      />
    </>
  );
};
