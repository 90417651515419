import { CheckboxOrRadioTrueFalse } from '../InertWasteApplicationForPriorAcceptance/CreateInertWasteApplicationForPriorAcceptanceFormSteps/FormComponents/FormCheckboxOrRadioInput';
import { FileUploadInformations } from '../InertWasteApplicationForPriorAcceptance/CreateInertWasteApplicationForPriorAcceptanceFormSteps/SpecificConditionsValidationStep/types';

export const INERT_WASTE_APPLICATION_EVALUATION_ID_QUERY_PARAM = 'evaluationId';

export type FilterByAuthorMetadataId = {
  authorMetadataId: string;
  clientCompanyId: number;
};
export type FilterByBusinessReferentId = {
  businessReferentId: string;
};
export type FilterByClientCompanyIdOnly = {
  clientCompanyId: number;
  isForClient: boolean;
};

export function isClientView(
  tableFilters?:
    | FilterByAuthorMetadataId
    | FilterByBusinessReferentId
    | FilterByClientCompanyIdOnly,
): tableFilters is FilterByAuthorMetadataId {
  return (
    tableFilters !== undefined && !!(tableFilters as FilterByAuthorMetadataId).authorMetadataId
  );
}

export function isBusinessReferentView(
  tableFilters?:
    | FilterByAuthorMetadataId
    | FilterByBusinessReferentId
    | FilterByClientCompanyIdOnly,
): tableFilters is FilterByBusinessReferentId {
  return (
    tableFilters !== undefined && !!(tableFilters as FilterByBusinessReferentId).businessReferentId
  );
}

export function isFilteringByClient(
  tableFilters?:
    | FilterByAuthorMetadataId
    | FilterByBusinessReferentId
    | FilterByClientCompanyIdOnly,
): tableFilters is FilterByClientCompanyIdOnly {
  return (
    tableFilters !== undefined && !!(tableFilters as FilterByClientCompanyIdOnly).clientCompanyId
  );
}

export enum InertWasteApplicationBaseEvaluationNotificationKeys {
  NOTIFICATION_DATE = 'notificationDate',
  NOTIFICATION_RECIPIENT_EMAIL = 'notificationRecipientEmail',
}

export type InertWasteApplicationBaseEvaluationNotificationFormInputs = {
  [InertWasteApplicationBaseEvaluationNotificationKeys.NOTIFICATION_DATE]: string;
  [InertWasteApplicationBaseEvaluationNotificationKeys.NOTIFICATION_RECIPIENT_EMAIL]: string;
};

export enum InertWasteApplicationBaseEvaluationKeys {
  IS_EXTERNAL_EVALUATOR_REQUIRED = 'isExternalEvaluatorRequired',
  EXTERNAL_EVALUATOR_EMAIL = 'externalEvaluatorEmail',
  IS_STEP_AGREED = 'isStepAgreed',
  EVALUATION_DATE = 'evaluationDate',
  EVALUATOR_NAME = 'evaluatorName',
  NOTIFICATIONS = 'notifications',
  OPTIONAL_COMMENT = 'optionalComment',
}

export enum InertWasteApplicationZephyrEvaluationKeys {
  APPLICATION_REFERENCE_NUMBER = 'applicationReferenceNumber',
  VALIDITY_DATE = 'validityDate',
  PRODUCT_CODE = 'productCode',
}

enum InertWasteApplicationSignatureEvaluationKeys {
  SIGNATURE_DATE = 'signatureDate',
}

type InertWasteApplicationBaseEvaluationFormInputs = {
  [InertWasteApplicationBaseEvaluationKeys.IS_EXTERNAL_EVALUATOR_REQUIRED]: CheckboxOrRadioTrueFalse;
  [InertWasteApplicationBaseEvaluationKeys.EXTERNAL_EVALUATOR_EMAIL]: string;
  [InertWasteApplicationBaseEvaluationKeys.IS_STEP_AGREED]: CheckboxOrRadioTrueFalse;
  [InertWasteApplicationBaseEvaluationKeys.EVALUATION_DATE]: string;
  [InertWasteApplicationBaseEvaluationKeys.EVALUATOR_NAME]: string;
  [InertWasteApplicationBaseEvaluationKeys.OPTIONAL_COMMENT]: string;
  [InertWasteApplicationBaseEvaluationKeys.NOTIFICATIONS]: InertWasteApplicationBaseEvaluationNotificationFormInputs[];
};

type InertWasteApplicationZephyrEvaluationFormInputs =
  InertWasteApplicationBaseEvaluationFormInputs & {
    [InertWasteApplicationZephyrEvaluationKeys.APPLICATION_REFERENCE_NUMBER]: string;
    [InertWasteApplicationZephyrEvaluationKeys.VALIDITY_DATE]: string;
    [InertWasteApplicationZephyrEvaluationKeys.PRODUCT_CODE]: string;
  };

type InertWasteApplicationSignatureEvaluationFormInputs =
  InertWasteApplicationBaseEvaluationFormInputs & {
    [InertWasteApplicationSignatureEvaluationKeys.SIGNATURE_DATE]: string;
  };

export enum InertWasteApplicationEvaluationFormStepsKeys {
  TECHNICAL = 'technical',
  SIGNATURE = 'signature',
  ZEPHYR = 'zephyr',
  /** @deprecated */
  IS_APPLICATION_APPROVED = 'isApplicationApproved',
  /** @deprecated */
  APPLICATION_REJECTION_REASON = 'applicationRejectionReason',
  /** @deprecated */
  APPLICATION_REFERENCE_NUMBER = 'applicationReferenceNumber',
  /** @deprecated */
  EVALUATED_APPLICATION_FILES = 'evaluatedApplicationFile',
}

export type InertWasteApplicationEvaluationFormStepsInputs = {
  [InertWasteApplicationEvaluationFormStepsKeys.TECHNICAL]: InertWasteApplicationBaseEvaluationFormInputs;
  [InertWasteApplicationEvaluationFormStepsKeys.SIGNATURE]: InertWasteApplicationSignatureEvaluationFormInputs;
  [InertWasteApplicationEvaluationFormStepsKeys.ZEPHYR]: InertWasteApplicationZephyrEvaluationFormInputs;
  [InertWasteApplicationEvaluationFormStepsKeys.IS_APPLICATION_APPROVED]: boolean;
  [InertWasteApplicationEvaluationFormStepsKeys.APPLICATION_REJECTION_REASON]: string | null;
  [InertWasteApplicationEvaluationFormStepsKeys.APPLICATION_REFERENCE_NUMBER]: string;
  [InertWasteApplicationEvaluationFormStepsKeys.EVALUATED_APPLICATION_FILES]:
    | FileUploadInformations[]
    | null;
};

export enum InertWasteApplicationEvaluationFormKeys {
  EVALUATION = 'evaluation',
}

export enum InertWasteApplicationDetailsCompletedStepsKeys {
  INITIATED = 'INITIATED',
  SENT_TO_PRODUCER = 'SENT_TO_PRODUCER',
  SIGNED_BY_PRODUCER = 'SIGNED_BY_PRODUCER',
  SIGNED_BY_APPLICANT = 'SIGNED_BY_APPLICANT',
  EVALUATED_TECHNICAL = 'EVALUATED_TECHNICAL',
  EVALUATED_ZEPHYR = 'EVALUATED_ZEPHYR',
  EVALUATED_SIGNATURE = 'EVALUATED_SIGNATURE',
}
