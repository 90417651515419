import { MATOMO_ANALYTICS_SITE_ID } from 'services/api/constants';
import { CustomDimension, TrackEventParams, TrackParams } from 'types/matomo';

import { AnalyticsInterface } from './AnalyticsInterface';
import { MATOMO_CONFIG, TRACK_TYPES } from './constants';

export class MatomoAnalytics implements AnalyticsInterface {
  async init(): Promise<void> {
    const _paq = (window._paq = window._paq || []);

    _paq.push([TRACK_TYPES.TRACK_PAGE_VIEW]);
    _paq.push(['enableLinkTracking']);
    const u = `https://${MATOMO_CONFIG.SUBSCRIPTION}.matomo.cloud/`;
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', MATOMO_ANALYTICS_SITE_ID]);
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = `https://cdn.matomo.cloud/${MATOMO_CONFIG.SUBSCRIPTION}.matomo.cloud/container_${MATOMO_CONFIG.CONTAINER_ID}.js`;
    s.parentNode?.insertBefore(g, s);
  }

  setUserConfig(userHash: string): void {
    this.pushInstruction('setUserId', userHash);
  }

  event(action: string, label: string, data: string): void {
    this.trackEvent({ category: data, action, name: label });
  }

  trackEvent({ category, action, name, value, ...otherParams }: TrackEventParams): void {
    if (category && action) {
      this.track({
        data: [TRACK_TYPES.TRACK_EVENT, category, action, name, value],
        ...otherParams,
      });
    } else {
      throw new Error(`Error: category and action are required.`);
    }
  }

  track({
    data = [],
    documentTitle = window.document.title,
    href,
    customDimensions = false,
  }: TrackParams): void {
    if (data.length) {
      if (customDimensions && Array.isArray(customDimensions) && customDimensions.length) {
        customDimensions.map((customDimension: CustomDimension) =>
          this.pushInstruction('setCustomDimension', customDimension.id, customDimension.value),
        );
      }

      this.pushInstruction('setCustomUrl', href ?? window.location.href);
      this.pushInstruction('setDocumentTitle', documentTitle);
      this.pushInstruction(...(data as [string, ...unknown[]]));
    }
  }

  pushInstruction(name: string, ...args: unknown[]) {
    if (typeof window !== 'undefined') {
      window._paq.push([name, ...args]);
    }
  }
}
