import { Dialog as MuiDialog } from '@material-ui/core/';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { IconButton } from 'components/IconButton/IconButton';
import { ReactNode } from 'react';

import {
  DialogStyleProps,
  StyledDialogContent,
  StyledDialogHeader,
  TitleContainer,
  TitleIconContainer,
  useDialogStyle,
} from './Dialog.style';

type Props = {
  isOpen: boolean;
  titleIcon?: ReactNode;
  onClose?: () => void;
  title?: string;
  titleIconColor?: 'grey' | 'orange' | 'blue';
  showCloseIcon?: boolean;
  closeIconSizeInPx?: number;
  children: ReactNode;
  titleColor?: string;
  noMarginBottomOnHeader?: boolean;
  fullPageDialog?: boolean;
} & DialogStyleProps;

export const Dialog = ({
  isOpen,
  titleIcon,
  onClose,
  title = '',
  titleIconColor = 'grey',
  showCloseIcon = false,
  closeIconSizeInPx = 24,
  widthInPercent,
  heightInPercent,
  children,
  titleColor,
  noMarginBottomOnHeader = false,
  fullPageDialog = false,
  minHeightPx,
}: Props): JSX.Element => {
  const classes = useDialogStyle({
    widthInPercent,
    heightInPercent,
    borderTopColor: titleColor,
    fullPageDialog,
    minHeightPx,
  })();
  return (
    <MuiDialog open={isOpen} onClose={onClose} scroll="paper" classes={classes}>
      <StyledDialogHeader noMarginBottom={noMarginBottomOnHeader}>
        {titleIcon && (
          <TitleIconContainer titleIconColor={titleIconColor}>{titleIcon}</TitleIconContainer>
        )}
        <TitleContainer color={titleColor} showCloseIcon={showCloseIcon}>
          {title}
        </TitleContainer>
        {onClose && showCloseIcon && (
          <IconButton size="medium" displayStyle="grey" onClick={onClose}>
            <CloseIcon widthInPx={closeIconSizeInPx} />
          </IconButton>
        )}
      </StyledDialogHeader>
      <StyledDialogContent>{children}</StyledDialogContent>
    </MuiDialog>
  );
};
