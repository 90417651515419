import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const GoToFileIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    style={{
      width: getSizeInPx(widthInPx ?? 20),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M11.6666 14.1667H14.9999V11.6667L19.1666 15.4167L14.9999 19.1667V16.6667H11.6666V14.1667ZM10.8333 7.50008H15.4166L10.8333 2.91675V7.50008ZM4.99992 1.66675H11.6666L16.6666 6.66675V10.2834C16.1416 10.1001 15.5833 10.0001 14.9999 10.0001C13.6738 10.0001 12.4021 10.5269 11.4644 11.4645C10.5267 12.4022 9.99992 13.674 9.99992 15.0001C9.99992 16.2834 10.4833 17.4501 11.2749 18.3334H4.99992C4.07492 18.3334 3.33325 17.5834 3.33325 16.6667V3.33341C3.33325 2.89139 3.50885 2.46746 3.82141 2.1549C4.13397 1.84234 4.55789 1.66675 4.99992 1.66675Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
