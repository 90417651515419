import { ApiAdresseFeature } from 'services/api/apiAdresse/types';

export enum ApplicantInformationFormKeys {
  APPLICANT_TYPE = 'applicantType',
  COMPANY_NAME = 'companyName',
  COMPANY_ACTIVITY = 'companyActivity',
  RESPONSIBLE_NAME = 'responsibleName',
  NAF_CODE = 'nafCode',
  SIRET_NUMBER = 'siretNumber',
  ADDRESS = 'address',
  ADDRESS_DATA = 'addressData',
  EMAIL_ADDRESS = 'emailAddress',
  PHONE_NUMBER = 'phoneNumber',
  FAX_NUMBER = 'faxNumber',
  ECO_ORGANISM = 'ecoOrganism',
}

export type ApplicantInformationForm = {
  [ApplicantInformationFormKeys.APPLICANT_TYPE]: ApplicantType | null;
  [ApplicantInformationFormKeys.COMPANY_NAME]: string | null;
  [ApplicantInformationFormKeys.COMPANY_ACTIVITY]: string | null;
  [ApplicantInformationFormKeys.RESPONSIBLE_NAME]: string | null;
  [ApplicantInformationFormKeys.NAF_CODE]: string | null;
  [ApplicantInformationFormKeys.SIRET_NUMBER]: string | null;
  [ApplicantInformationFormKeys.ADDRESS]: string | null;
  [ApplicantInformationFormKeys.ADDRESS_DATA]: ApiAdresseFeature | null;
  [ApplicantInformationFormKeys.EMAIL_ADDRESS]: string | null;
  [ApplicantInformationFormKeys.PHONE_NUMBER]: string | null;
  [ApplicantInformationFormKeys.FAX_NUMBER]: string | null;
  [ApplicantInformationFormKeys.ECO_ORGANISM]: string | null;
};

export enum ApplicantType {
  APPLICANT = 'applicant',
  APPLICANT_PRODUCER = 'applicantProducer',
}
