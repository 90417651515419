import emptyApiResponse from 'assets/images/WarningLogo.png';
import { Button } from 'components/Button/Button';
import { getSpacing } from 'stylesheet';

import {
  EmptyApiResponseContainer,
  EmptyApiResponseDescription,
  EmptyApiResponseImage,
  EmptyApiResponseTitle,
} from './EmptyApiResponse.style';

export interface EmptyStatusImageProps {
  src: string;
}

type Props = {
  title?: string;
  description?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  emptyStatusImageProps?: EmptyStatusImageProps;
};

const defaultEmptyStatusImageProps = {
  src: emptyApiResponse,
};

export const EmptyApiResponse = ({
  title,
  description,
  buttonLabel,
  onButtonClick,
  emptyStatusImageProps,
}: Props): JSX.Element => {
  return (
    <EmptyApiResponseContainer>
      <EmptyApiResponseImage {...(emptyStatusImageProps ?? defaultEmptyStatusImageProps)} />
      {title && <EmptyApiResponseTitle>{title}</EmptyApiResponseTitle>}
      {description && <EmptyApiResponseDescription>{description}</EmptyApiResponseDescription>}
      {buttonLabel && (
        <Button
          displayStyle="filledOrange"
          onClick={onButtonClick}
          bigPaddings
          margins={`${getSpacing(3)} 0 0 0`}
        >
          {buttonLabel}
        </Button>
      )}
    </EmptyApiResponseContainer>
  );
};
