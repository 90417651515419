import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getInertWasteApplicationForPriorAcceptanceTable } from 'redux/Tables/selectors';
import { getNumberOfUnreadInertWasteApplicationsForPermission } from 'services/api/inertWasteApplicationForPriorAcceptance/client';

import { useAppDispatch } from '../../../redux/hooks';
import {
  getNumberOfUnreadInertWasteApplications,
  getSelectedPermission,
} from '../../../redux/User/selectors';
import { setNumberOfUnreadInertWasteApplications } from '../../../redux/User/slice';
import { GoToFileIcon } from '../GoToFileIcon';
import { IconPossiblyLateralProps } from '../types';
import { NotificationCounterBubble, NotificationIconContainer } from './NotificationIcon.style';

export const InertWasteApplicationNotificationIcon = ({
  widthInPx,
  heightInPx,
  isLateralMenuIcon,
  color,
}: IconPossiblyLateralProps): JSX.Element => {
  const selectedPermission = useSelector(getSelectedPermission);
  const numberOfUnreadInertWasteApplications = useSelector(getNumberOfUnreadInertWasteApplications);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isInertWasteApplicationTableReloading = useSelector(
    getInertWasteApplicationForPriorAcceptanceTable,
  ).isReloading;

  const fetchCountUnreadInertWasteApplications = useCallback(async () => {
    if (selectedPermission && isLateralMenuIcon) {
      const countInertWasteApplicationsUnreadForPermission =
        await getNumberOfUnreadInertWasteApplicationsForPermission(selectedPermission.id);

      dispatch(
        setNumberOfUnreadInertWasteApplications({
          numberOfUnreadInertWasteApplications:
            countInertWasteApplicationsUnreadForPermission.countUnreadInertWasteApplicationViews,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isLateralMenuIcon,
    selectedPermission,
    location,
    isInertWasteApplicationTableReloading,
  ]);

  useEffect(() => {
    fetchCountUnreadInertWasteApplications();
  }, [fetchCountUnreadInertWasteApplications]);

  const numberOfMessagesToDisplay = useMemo(() => {
    const MAX_NUMBER_OF_MESSAGES_DISPLAYED = 9;

    return numberOfUnreadInertWasteApplications > MAX_NUMBER_OF_MESSAGES_DISPLAYED
      ? `>${MAX_NUMBER_OF_MESSAGES_DISPLAYED}`
      : numberOfUnreadInertWasteApplications;
  }, [numberOfUnreadInertWasteApplications]);

  return (
    <NotificationIconContainer>
      <GoToFileIcon widthInPx={widthInPx} heightInPx={heightInPx} color={color}></GoToFileIcon>
      {numberOfUnreadInertWasteApplications > 0 && (
        <NotificationCounterBubble isLateralMenuIcon={isLateralMenuIcon}>
          {numberOfMessagesToDisplay}
        </NotificationCounterBubble>
      )}
    </NotificationIconContainer>
  );
};
