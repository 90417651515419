import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const HelpAndContactIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    style={{
      width: getSizeInPx(widthInPx ?? 20),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M10.0001 1.66675C5.40008 1.66675 1.66675 5.40008 1.66675 10.0001C1.66675 14.6001 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 5.40008 14.6001 1.66675 10.0001 1.66675ZM10.8334 15.8334H9.16675V14.1667H10.8334V15.8334ZM12.5584 9.37508L11.8084 10.1417C11.2084 10.7501 10.8334 11.2501 10.8334 12.5001H9.16675V12.0834C9.16675 11.1667 9.54175 10.3334 10.1417 9.72508L11.1751 8.67508C11.4834 8.37508 11.6667 7.95842 11.6667 7.50008C11.6667 6.58342 10.9167 5.83342 10.0001 5.83342C9.08342 5.83342 8.33342 6.58342 8.33342 7.50008H6.66675C6.66675 5.65841 8.15841 4.16675 10.0001 4.16675C11.8417 4.16675 13.3334 5.65841 13.3334 7.50008C13.3334 8.23342 13.0334 8.90008 12.5584 9.37508Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
