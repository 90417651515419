export const PLATFORM_LEGAL_CORPORATION_ID =
  window?._env_?.legalCorporation?.id ??
  window?.appConfig?.legalCorporation?.id ??
  process.env.REACT_APP_PLATFORM_LEGAL_CORPORATION_ID ??
  '';

export const PLATFORM_LEGAL_CORPORATION_LABEL =
  window?._env_?.legalCorporation?.label ??
  window?.appConfig?.legalCorporation?.label ??
  process.env.REACT_APP_PLATFORM_LEGAL_CORPORATION_LABEL ??
  '';

export const PLATFORM_SITES_MAP_CENTER_LATITUDE =
  window?._env_?.sitesMap?.centerLatitude ??
  window?.appConfig?.sitesMap?.centerLatitude ??
  process.env.REACT_APP_PLATFORM_SITES_MAP_CENTER_LATITUDE ??
  '';

export const PLATFORM_SITES_MAP_CENTER_LONGITUDE =
  window?._env_?.sitesMap?.centerLongitude ??
  window?.appConfig?.sitesMap?.centerLongitude ??
  process.env.REACT_APP_PLATFORM_SITES_MAP_CENTER_LONGITUDE ??
  '';

export const PLATFORM_IS_DAP_ENABLED =
  window?._env_?.featureFlags?.isDapEnabled ??
  window?.appConfig?.featureFlags?.isDapEnabled ??
  process.env.REACT_APP_PLATFORM_IS_DAP_ENABLED ??
  true;

export const CUSTOMER_AGREEMENT_VERSION = parseInt(
  window?._env_?.customerAgreementVersion ??
    window?.appConfig?.customerAgreementVersion ??
    process.env.REACT_APP_CUSTOMER_AGREEMENT_VERSION ??
    '0',
);

export const CASIAS_BASIAS_MAP_BASE_URL =
  window?.appConfig?.casiasBasiasMapBaseUrl ??
  process.env.REACT_APP_CASIAS_BASIAS_MAP_BASE_URL ??
  '';

export const RELEASE_NOTES_VERSION =
  window?.appConfig?.releaseNotesVersion ?? process.env.REACT_APP_RELEASE_NOTES_VERSION ?? 'v0';

export const CONTACT_MAIL_ADDRESS =
  window?._env_?.contactMailAddress ??
  window?.appConfig?.contactMailAddress ??
  process.env.REACT_APP_CONTACT_MAIL_ADDRESS ??
  '';

export const HTTP_OK_STATUS_CODE = 200;
export const HTTP_BAD_REQUEST_STATUS_CODE = 400;
export const HTTP_NOT_FOUND_STATUS_CODE = 404;
export const HTTP_CONFLICT_STATUS_CODE = 409;
export const GOOGLE_ANALYTICS_TRACKIND_ID =
  window?.appConfig?.googleAnalyticsTrackingId ??
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_ID ??
  '';

export const DATADOG_CLIENT_TOKEN =
  window?.appConfig?.datadog?.clientToken ?? process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '';
export const DATADOG_SITE =
  window?.appConfig?.datadog?.site ?? process.env.REACT_APP_DATADOG_SITE ?? '';
export const DATADOG_ENV =
  window?.appConfig?.datadog?.env ?? process.env.REACT_APP_DATADOG_ENV ?? '';
export const DATADOG_PROJECT_SERVICE =
  window?.appConfig?.datadog?.projectService ?? process.env.REACT_APP_DATADOG_PROJECT_SERVICE ?? '';

export const AZURE_AD_B2C_REDIRECTION_URI =
  window?._env_?.azureAdB2C?.redirectionUri ??
  window?.appConfig?.azureAdB2C?.redirectUri ??
  process.env.REACT_APP_AUTH_AZURE_REDIRECT_URI;
export const AZURE_AD_B2C_CLIENT_ID =
  window?._env_?.azureAdB2C?.clientId ??
  window?.appConfig?.azureAdB2C?.clientId ??
  process.env.REACT_APP_AUTH_AZURE_CLIENT_ID;

export const HCAPTCHA_SITE_KEY =
  window?.appConfig?.hCaptchaSiteKey ?? process.env.REACT_APP_HCAPTCHA_SITE_KEY ?? '';

export const MATOMO_ANALYTICS_SITE_ID =
  window?.appConfig?.matomoAnalyticsSiteId ??
  process.env.REACT_APP_MATOMO_ANALYTICS_SITE_ID ??
  '20';
