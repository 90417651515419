import { apiClient } from '../apiClient';
import { RELEASE_NOTES_VERSION } from '../constants';
import { ApiActionResponse } from '../types';
import { CreateReleaseNoteRequestBody, PartialCreateReleaseNoteRequestBody } from './types';

export const createReleaseNote = (
  partialCustomerAgreementRequestBody: PartialCreateReleaseNoteRequestBody,
) => {
  const data: CreateReleaseNoteRequestBody = {
    ...partialCustomerAgreementRequestBody,
    version: RELEASE_NOTES_VERSION,
  };
  return apiClient.request<ApiActionResponse>('POST', '/release-note', data);
};
