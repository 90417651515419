import underConstruction from 'assets/images/UnderConstruction.png';
import { Button } from 'components/Button/Button';
import { FunctionComponent } from 'react';

// import { Sentry } from 'services/sentry';
import { Container, DetailsContainer, PageContent, Title } from './AppCrashFallback.style';

/**
 * Error page inspiration https://medium.com/design-ideas-thoughts/designing-error-pages-8d82e16e3472
 */

export interface FallbackProps {
  eventId: string;
}

// TS reports `showReportDialog` as not being a property of `@sentry/node`. This
// is correct, except we use `@sentry/browser` in the browser!
// const reportDialog = (eventId: string) => () => Sentry.showReportDialog({ eventId });

export const AppCrashFallback: FunctionComponent<FallbackProps> = ({ eventId }) => {
  return (
    <main style={{ height: '100%' }}>
      {/* The <main> tag needs to wrap this component because with redux errors,
      style is not applied to the root tag of this component */}
      <Container>
        <PageContent>
          <img src={underConstruction} alt="" />
          <Title>Une erreur est survenue</Title>
          <DetailsContainer>
            <p>Nos équipes ont été notifiées du problème et sont en train de l’investiguer.</p>
            <p>Vous pouvez rafraîchir la page ou réessayer plus tard si le problème persiste.</p>
          </DetailsContainer>
          <Button displayStyle="filledOrange" onClick={() => window.location.reload()}>
            Rafraîchir la page
          </Button>
        </PageContent>
      </Container>
    </main>
  );
};
