import { TableFilters } from '../filtersKeys';

export enum ClientCompaniesFiltersKeys {
  TYPE = 'isInternal',
  ID_MDM = 'id',
  CLIENT_COMPANY_NAME = 'name',
  SIRET = 'siret',
  ACCESS_STATUS = 'clientCompanyLegalCorporations.accessStatus',
  ACCESS_CREATION_DATE = 'clientCompanyLegalCorporations.accessCreationDate',
}

export const CLIENT_COMPANIES_FILTERS_INITIAL_VALUES: TableFilters = {
  [ClientCompaniesFiltersKeys.TYPE]: '',
  [ClientCompaniesFiltersKeys.ID_MDM]: '',
  [ClientCompaniesFiltersKeys.CLIENT_COMPANY_NAME]: '',
  [ClientCompaniesFiltersKeys.SIRET]: '',
  [ClientCompaniesFiltersKeys.ACCESS_STATUS]: '',
  [ClientCompaniesFiltersKeys.ACCESS_CREATION_DATE]: { before: null, after: null },
};
