import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { AppDispatch, persistor, RootState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const usePersistReduxStoreAndReloadPage = () => {
  const history = useHistory();
  const persistReduxStoreAndReloadPage = async () => {
    await persistor.flush();
    history.go(0);
  };

  return persistReduxStoreAndReloadPage;
};
