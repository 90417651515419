import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ExternalUserState = {
  hasAccess?: boolean; // if hasAccess is undefined => the client is not the DB
};

const EXTERNAL_USER_STORE_KEY = 'externalUser';

const initialState: ExternalUserState = {
  hasAccess: undefined,
};

const externalUserSlice = createSlice({
  name: EXTERNAL_USER_STORE_KEY,
  initialState,
  reducers: {
    setHasAccess: (state, action: PayloadAction<boolean | undefined>) => {
      state.hasAccess = action.payload;
    },
  },
});

export const { setHasAccess } = externalUserSlice.actions;
export const externalUserReducer = externalUserSlice.reducer;
