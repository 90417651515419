import { media } from 'breakpoints';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { frameHeight, getSpacing } from 'stylesheet';

export const HeaderContainer = styled.header<{ isNotMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: max-content;
  padding: ${getSpacing(2)};
  row-gap: ${getSpacing(1)};
  ${({ isNotMobile }) => isNotMobile && `padding-left: ${getSpacing(5)}`};
`;

export const HeaderMenuIconContainer = styled.div`
  order: 1;
`;
export const HeaderBrandImageContainer = styled(Link)`
  max-height: ${frameHeight.header};
  order: 2;
`;
export const HeaderClinetCompaniesSelectContainer = styled.div`
  order: 3;
  ${media.md`
    width: 100%;
    order: 4;
  `}
`;
export const HeaderUserCardContainer = styled.div`
  order: 4;
  ${media.md`
    order: 3;
  `}
`;

export const HeaderBrandImage = styled.img`
  height: 100%;
  width: 100%;
  max-width: 130px;
  object-fit: contain;
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${getSpacing(1)};
`;
