import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: #f4f6fa;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${getSpacing(8)} ${getSpacing(4)};
  gap: ${getSpacing(4)};
`;

export const Title = styled.h1`
  font-weight: bold;
  margin: 0;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${getSpacing(1)};

  > p {
    margin: 0;
  }
`;
