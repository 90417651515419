import makeStyles from '@material-ui/core/styles/makeStyles';
import { media } from 'breakpoints';
import styled, { css } from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

export const LateralMenuContainer = styled.menu`
  width: ${getSpacing(10)};
  padding: ${getSpacing(10)} ${getSpacing(0.5)};
  background-color: ${colorPalette.primary};
`;

export const lateralMenuStyle = {
  itemContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: inherit;
    margin-bottom: ${getSpacing(1.5)};
    color: ${colorPalette.grey_400};
    text-decoration: none;
    &:hover,
    &:focus {
      color: ${colorPalette.white};
      cursor: pointer;
    }
    ${media.sm`
      flex-direction: row;
      gap: 12px;
      padding: 18px 16px;
    `}
  `,
  itemLabel: css`
    ${typography.p3_regular};
    margin-top: ${getSpacing(0.25)};
    color: currentColor;
    text-align: center;
  `,
};

export const useMenuDivider = () =>
  makeStyles({
    root: {
      backgroundColor: colorPalette.neutral_light,
      margin: getSpacing(2),
    },
  })();
