import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const HistoryIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    style={{
      width: getSizeInPx(widthInPx ?? 20),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M11.25 2.5C7.10833 2.5 3.75 5.85833 3.75 10H1.25L4.49167 13.2417L4.55 13.3583L7.91667 10H5.41667C5.41667 6.775 8.025 4.16667 11.25 4.16667C14.475 4.16667 17.0833 6.775 17.0833 10C17.0833 13.225 14.475 15.8333 11.25 15.8333C9.64167 15.8333 8.18333 15.175 7.13333 14.1167L5.95 15.3C7.30833 16.6583 9.175 17.5 11.25 17.5C15.3917 17.5 18.75 14.1417 18.75 10C18.75 5.85833 15.3917 2.5 11.25 2.5ZM10.4167 6.66667V10.8333L13.9833 12.95L14.5833 11.9417L11.6667 10.2083V6.66667H10.4167Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
