import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { ApiPermissionInList } from 'services/api/permissions/types';
import { findSelectedPermission } from 'services/utils/permissions';

export type UserState = {
  permissions: ApiPermissionInList[];
  selectedPermissionId: number | null;
  numberOfUnreadMessages: number;
  numberOfUnreadInertWasteApplications: number;
};

const USER_STORE_KEY = 'user';
const SELECTED_PERMISSION_ID_KEY = 'selectedPermissionId';

export const userPersistConfig = {
  key: USER_STORE_KEY,
  whitelist: [SELECTED_PERMISSION_ID_KEY],
  storage,
};

const initialState: UserState = {
  permissions: [],
  [SELECTED_PERMISSION_ID_KEY]: null,
  numberOfUnreadMessages: 0,
  numberOfUnreadInertWasteApplications: 0,
};

const userSlice = createSlice({
  name: USER_STORE_KEY,
  initialState,
  reducers: {
    storePermissions: (state, action: PayloadAction<ApiPermissionInList[]>) => {
      state.permissions = action.payload;

      const persistedSelectedPermissionId = state.selectedPermissionId;

      const permissionMatchingPersistedSelectedPermissionId = findSelectedPermission(
        action.payload,
        persistedSelectedPermissionId,
      );

      state.selectedPermissionId = permissionMatchingPersistedSelectedPermissionId?.id || null;
    },
    updateSelectedPermissionId: (state, action: PayloadAction<number>) => {
      state.selectedPermissionId = action.payload;
    },
    addCustomerAgreementToPermission: (
      state,
      action: PayloadAction<{ permissionId: number; version: number }>,
    ) => {
      const permission = findSelectedPermission(state.permissions, action.payload.permissionId);

      if (permission) {
        permission.customerAgreements.push({ version: action.payload.version });
      }
    },
    addReleaseNoteToPermission: (
      state,
      action: PayloadAction<{ permissionId: number; version: string }>,
    ) => {
      const permission = findSelectedPermission(state.permissions, action.payload.permissionId);

      if (permission) {
        permission.releaseNotes.push({ version: action.payload.version });
      }
    },
    setNumberOfUnreadMessages: (
      state,
      action: PayloadAction<{ numberOfUnreadMessages: number }>,
    ) => {
      state.numberOfUnreadMessages = action.payload.numberOfUnreadMessages;
    },
    setNumberOfUnreadInertWasteApplications: (
      state,
      action: PayloadAction<{ numberOfUnreadInertWasteApplications: number }>,
    ) => {
      state.numberOfUnreadInertWasteApplications =
        action.payload.numberOfUnreadInertWasteApplications;
    },
    decreaseNumberOfUnreadMessages: (state) => {
      if (state.numberOfUnreadMessages > 0) {
        state.numberOfUnreadMessages--;
      }
    },
    decreaseNumberOfUnreadInertWasteApplications: (state) => {
      if (state.numberOfUnreadInertWasteApplications > 0) {
        state.numberOfUnreadInertWasteApplications--;
      }
    },
  },
});

export const {
  storePermissions,
  updateSelectedPermissionId,
  addCustomerAgreementToPermission,
  addReleaseNoteToPermission,
  setNumberOfUnreadMessages,
  setNumberOfUnreadInertWasteApplications,
  decreaseNumberOfUnreadMessages,
  decreaseNumberOfUnreadInertWasteApplications,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
