import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const ClientIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    style={{
      width: getSizeInPx(widthInPx ?? 20),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M13.3333 9.16675C14.7166 9.16675 15.8249 8.05008 15.8249 6.66675C15.8249 5.28341 14.7166 4.16675 13.3333 4.16675C11.9499 4.16675 10.8333 5.28341 10.8333 6.66675C10.8333 8.05008 11.9499 9.16675 13.3333 9.16675ZM6.66658 9.16675C8.04992 9.16675 9.15825 8.05008 9.15825 6.66675C9.15825 5.28341 8.04992 4.16675 6.66658 4.16675C5.28325 4.16675 4.16658 5.28341 4.16658 6.66675C4.16658 8.05008 5.28325 9.16675 6.66658 9.16675ZM6.66658 10.8334C4.72492 10.8334 0.833252 11.8084 0.833252 13.7501V15.8334H12.4999V13.7501C12.4999 11.8084 8.60825 10.8334 6.66658 10.8334ZM13.3333 10.8334C13.0916 10.8334 12.8166 10.8501 12.5249 10.8751C13.4916 11.5751 14.1666 12.5167 14.1666 13.7501V15.8334H19.1666V13.7501C19.1666 11.8084 15.2749 10.8334 13.3333 10.8334Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
