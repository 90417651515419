import { DialogContent, makeStyles, withStyles } from '@material-ui/core';
import { useIsMobile } from 'breakpoints';
import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

export type DialogStyleProps = {
  widthInPercent?: number;
  heightInPercent?: number;
  borderTopColor?: string;
  fullPageDialog?: boolean;
  minHeightPx?: number;
};

export const useDialogStyle = ({
  widthInPercent,
  heightInPercent,
  borderTopColor = colorPalette.white,
  fullPageDialog,
  minHeightPx,
}: DialogStyleProps) => {
  const isMobile = useIsMobile();
  return makeStyles({
    paper: {
      padding: `${getSpacing(1)} ${getSpacing(3)} ${getSpacing(3)} ${getSpacing(3)}`,
      borderTop: `${getSpacing(2)} solid ${borderTopColor}`,
      ...(widthInPercent
        ? { width: `${widthInPercent}%`, maxWidth: `${widthInPercent}%` }
        : { minWidth: '400px', maxWidth: '800px' }),
      ...(heightInPercent
        ? { height: `${heightInPercent}%` }
        : { minHeight: '200px', maxHeight: '90%' }),
      borderRadius: getSpacing(2),
      ...(isMobile ? { margin: `${getSpacing(1)}` } : {}),
      ...(fullPageDialog ? getFullPageDialogStyle() : {}),
      ...(minHeightPx ? { minHeight: `${minHeightPx}px` } : {}),
    },
  });
};

const getFullPageDialogStyle = () => ({
  margin: 0,
  minHeight: '100%',
  minWidth: '100%',
});

export const StyledDialogContent = withStyles({
  root: {
    padding: 0,
  },
})(DialogContent);

export const StyledDialogHeader = styled.div<{ noMarginBottom?: boolean }>`
  margin-bottom: ${(props) => (props.noMarginBottom ? 0 : getSpacing(3))};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.div<{ showCloseIcon: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.showCloseIcon ? 'start' : 'center')};
  ${typography.title_xsmall};
  color: ${(props) => props.color || 'currentColor'};
`;

const getTitleIconColor = (titleIconColor?: string) => {
  switch (titleIconColor) {
    case 'grey':
      return colorPalette.greyMain;
    case 'orange':
      return colorPalette.secondary_600;
    case 'blue':
      return colorPalette.blue_500;
  }
};

export const TitleIconContainer = styled.div<{ titleIconColor?: string }>`
  width: ${getSpacing(5)};
  height: ${getSpacing(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => getTitleIconColor(props.titleIconColor)};
  margin-right: ${getSpacing(2)};
`;
