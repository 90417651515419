import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const UserIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    style={{
      width: getSizeInPx(widthInPx ?? 20),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M10 10.625C11.3583 10.625 12.5583 10.95 13.5333 11.375C14.4333 11.775 15 12.675 15 13.65V15H5V13.6583C5 12.675 5.56667 11.775 6.46667 11.3833C7.44167 10.95 8.64167 10.625 10 10.625ZM3.33333 10.8333C4.25 10.8333 5 10.0833 5 9.16667C5 8.25 4.25 7.5 3.33333 7.5C2.41667 7.5 1.66667 8.25 1.66667 9.16667C1.66667 10.0833 2.41667 10.8333 3.33333 10.8333ZM4.275 11.75C3.96667 11.7 3.65833 11.6667 3.33333 11.6667C2.50833 11.6667 1.725 11.8417 1.01667 12.15C0.4 12.4167 0 13.0167 0 13.6917V15H3.75V13.6583C3.75 12.9667 3.94167 12.3167 4.275 11.75ZM16.6667 10.8333C17.5833 10.8333 18.3333 10.0833 18.3333 9.16667C18.3333 8.25 17.5833 7.5 16.6667 7.5C15.75 7.5 15 8.25 15 9.16667C15 10.0833 15.75 10.8333 16.6667 10.8333ZM20 13.6917C20 13.0167 19.6 12.4167 18.9833 12.15C18.275 11.8417 17.4917 11.6667 16.6667 11.6667C16.3417 11.6667 16.0333 11.7 15.725 11.75C16.0583 12.3167 16.25 12.9667 16.25 13.6583V15H20V13.6917ZM10 5C11.3833 5 12.5 6.11667 12.5 7.5C12.5 8.88333 11.3833 10 10 10C8.61667 10 7.5 8.88333 7.5 7.5C7.5 6.11667 8.61667 5 10 5Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
