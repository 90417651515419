import { TableFilters } from '../filtersKeys';

export enum InertWasteApplicationForPriorAcceptanceFiltersKeys {
  ID = 'id',
  CLIENT_COMPANY_NAME = 'clientCompany.name',
  REFERENCE = 'reference',
  WORKSITE_LABEL = 'worksiteLabel',
  WASTE_CODE = 'wasteCode',
  CREATED_AT = 'createdAt',
  AUTHOR = 'author',
  STATUS_LABEL = 'status.label',
  STATUS_CODE = 'status.code',
  SITE_ID = 'site.id',
  ID_HINT_TEXT = 'idHintText',
}

export const INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_FILTERS_INITIAL_VALUES: TableFilters = {
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.REFERENCE]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.CLIENT_COMPANY_NAME]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.ID]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.WORKSITE_LABEL]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.WASTE_CODE]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.CREATED_AT]: { before: null, after: null },
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.AUTHOR]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.STATUS_LABEL]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.STATUS_CODE]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.SITE_ID]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.ID_HINT_TEXT]: '',
};
