import { LateralMenuContainer } from './LateralMenu.style';
import { MenuItems } from './MenuItems';

export const LateralMenu = (): JSX.Element => {
  return (
    <LateralMenuContainer>
      <MenuItems />
    </LateralMenuContainer>
  );
};
