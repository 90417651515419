export enum InertWasteApplicationForPriorAcceptanceFormKeys {
  WASTE_IDENTIFICATION = 'wasteIdentification',
  SPECIFIC_CONDITIONS_VALIDATION = 'specificConditionsValidation',
  SITE_IDENTIFICATION = 'siteIdentification',
  DROPZONE_IDENTIFICATION = 'dropzoneIdentification',
  APPLICANT_INFORMATION = 'applicantInformation',
  PRODUCER_INFORMATION = 'producerInformation',
  SUMMARY_AND_VALIDATION = 'summaryAndValidation',
  ADDITIONAL_DOCUMENTS = 'additionalDocuments',
}
