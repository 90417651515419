import { makeStyles } from '@material-ui/core';
import { colorPalette, fontFamily, fontSize, getSpacing } from 'stylesheet';

export const useSelectOrInputFieldStyle = (width?: number, isLabelAnimated?: boolean) => {
  const additionalClassesForAnimatedLabel = isLabelAnimated
    ? {
        '& .MuiOutlinedInput-input': {
          padding: `${getSpacing(1)} ${getSpacing(2)} ${getSpacing(1)} ${getSpacing(1)}`,
        },
        '& .MuiInputLabel-formControl': {
          top: '-5px',
          '&.Mui-focused': {
            color: colorPalette.grey_600,
            top: '0px',
          },
          '&.MuiInputLabel-shrink': {
            top: '0px',
          },
          '&.Mui-error': {
            color: colorPalette.status_error,
          },
        },
      }
    : {};
  return makeStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily.main,
        fontSize: fontSize.p1,
        lineHeight: '1.5',
        backgroundColor: colorPalette.white,
        color: colorPalette.grey_600,
        borderColor: colorPalette.grey_400,
        width: width ? `${width}px` : '100%',
        padding: 0,
        '& .MuiSelect-root': {
          padding: `${getSpacing(1)} ${getSpacing(4)} ${getSpacing(1)} ${getSpacing(2)}`,
          '&:focus': {
            backgroundColor: colorPalette.white,
          },
        },
        '&.Mui-focused fieldset': {
          border: `1px solid ${colorPalette.grey_600}`,
        },
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: colorPalette.status_error,
      },
      '& .MuiFormHelperText-contained': {
        margin: `${getSpacing(0.5)} 0`,
        fontFamily: fontFamily.main,
        fontSize: fontSize.p3,
        lineHeight: '1.5',
        color: colorPalette.status_error,
      },
      '& .MuiOutlinedInput-multiline': {
        padding: `${getSpacing(1)} ${getSpacing(4)} ${getSpacing(1)} ${getSpacing(2)}`,
      },
      ...additionalClassesForAnimatedLabel,
    },
  });
};
