import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const AgreementConditionsContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${getSpacing(6)};
`;
