export enum FilterKey {
  SEARCH = 'search',
}

export type TextFilter = string;

export type RangeFilter = { min: number | null; max: number | null };

export type DateFilter = { before: string | null; after: string | null };

type BooleanFilter = 'true' | 'false' | '';

export type Filter = TextFilter | RangeFilter | DateFilter | BooleanFilter;

export type TableFilters = Record<string, Filter>;

export enum TableSortValue {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export type TableSort = {
  key: string;
  value: TableSortValue;
};
