import { ApiPermissionInList } from 'services/api/permissions/types';

export const convertPermissionToSelectInputOption = (permission: ApiPermissionInList) => ({
  label: permission.clientCompany
    ? `${permission.clientCompany.name} - ${permission.clientCompany.id}`
    : '',
  value: permission.id,
});

export const findSelectedPermission = (
  permissions: ApiPermissionInList[],
  searchedPermissionId: number | null,
): ApiPermissionInList | null => {
  if (permissions.length > 0) {
    const selectedPermission = permissions.find(
      (permission) => permission.id === searchedPermissionId,
    );
    return selectedPermission || permissions[0];
  }
  return null;
};
