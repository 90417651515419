const CLIENT_COMPANY_BASE_ROUTE = '/client-company/:clientCompanyId(\\d+)';
const SUBSCRIPTION_REQUEST_BASE_ROUTE = '/subscription-request';
const USERS = '/users';
const HISTORY = '/history';
const BUSINESS_REFERENTS = '/business-referents';
const CLIENT_REQUESTS = '/client-requests';
const BUSINESS_ADMIN_CLIENT_REQUESTS = '/business-admin-client-requests';
const INERT_WASTE_APPLICATION_PRODUCER_SIGNATURE = '/signature-dap-producteur';
const INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION = '/dap-evaluation';
const INERT_WASTE_APPLICATION_GENERATOR = '/generateur-dap';

export const ROUTES = {
  HOME: '/home',
  HISTORY: HISTORY,
  CLIENT_COMPANIES: '/client-companies',
  CLIENT_COMPANY: CLIENT_COMPANY_BASE_ROUTE,
  USERS: USERS,
  CLIENT_COMPANY_USERS: `${CLIENT_COMPANY_BASE_ROUTE}${USERS}`,
  CLIENT_COMPANY_HISTORY: `${CLIENT_COMPANY_BASE_ROUTE}${HISTORY}`,
  CLIENT_COMPANY_BUSINESS_REFERENTS: `${CLIENT_COMPANY_BASE_ROUTE}${BUSINESS_REFERENTS}`,
  SITES_AND_DOCUMENTS: '/sites-and-documents',
  CLIENT_REQUESTS: CLIENT_REQUESTS,
  INERT_WASTE_APPLICATION: '/inert-waste-application',
  INVITATION_PROCESS_END: '/invitation-process-end',
  NOT_FOUND: '/not-found',
  SUBSCRIPTION_REQUEST: SUBSCRIPTION_REQUEST_BASE_ROUTE,
  SUBSCRIPTION_REQUEST_SUCCESS: `${SUBSCRIPTION_REQUEST_BASE_ROUTE}/success`,
  HEALTH_CHECK_FRONTEND: '/health-check-frontend',
  BUSINESS_ADMIN_CLIENT_REQUESTS: BUSINESS_ADMIN_CLIENT_REQUESTS,
  BUSINESS_ADMIN_INERT_WASTE_APPLICATIONS: '/business-admin-inert-waste-applications',
  BUSINESS_REFERENTS: `${BUSINESS_REFERENTS}`,
  INERT_WASTE_APPLICATION_SIGNATURE_FROM_WASTE_PRODUCER: `${INERT_WASTE_APPLICATION_PRODUCER_SIGNATURE}`,
  INERT_WASTE_APPLICATION_SIGNATURE_ASYNC_SUCCESS: `/dap-success-async`,
  INERT_WASTE_APPLICATION_EVALUATION_ASYNC_SUCCESS: `/dap-evaluation-success-async`,
  INERT_WASTE_APPLICATION_EXTERNAL_SYNCHRONIZATION_FAILURE: `/dap-evaluation-failure`,
  INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION: INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION,
  INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION_SIGNATURE_END: `${INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION}/singature-end`,
  B2C_SILENT_REDIRECT: '/b2c-silent-token-redirect',
  ELECTRONIC_SIGNATURE_END: '/signature-end',
  REFERENTS_MANAGEMENT: '/referents-management',
  EXTERNAL_FORM_ACCESS: '/external-form-access',
  INERT_WASTE_APPLICATION_GENERATOR: `${INERT_WASTE_APPLICATION_GENERATOR}`,
  INERT_WASTE_APPLICATION_GENERATOR_SUCCESS: `${INERT_WASTE_APPLICATION_GENERATOR}/success`,
};

export const UNAUTHENTICATED_ROUTES = [
  ROUTES.INERT_WASTE_APPLICATION_SIGNATURE_ASYNC_SUCCESS,
  ROUTES.INERT_WASTE_APPLICATION_EVALUATION_ASYNC_SUCCESS,
  ROUTES.INERT_WASTE_APPLICATION_SIGNATURE_FROM_WASTE_PRODUCER,
  ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION,
  ROUTES.SUBSCRIPTION_REQUEST,
  ROUTES.SUBSCRIPTION_REQUEST_SUCCESS,
  ROUTES.B2C_SILENT_REDIRECT,
  ROUTES.ELECTRONIC_SIGNATURE_END,
  ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_SYNCHRONIZATION_FAILURE,
  ROUTES.EXTERNAL_FORM_ACCESS,
  ROUTES.INERT_WASTE_APPLICATION_GENERATOR,
  ROUTES.INERT_WASTE_APPLICATION_EXTERNAL_EVALUATION_SIGNATURE_END,
];
