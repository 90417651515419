import { TableFilters } from '../filtersKeys';

export enum InvoicesFiltersKeys {
  EXPLOITATION_UNIT_NAME = 'exploitationUnit.name',
  IDENTIFIER = 'identifier',
  INVOICED_AT = 'invoicedAt',
  PRICE_WITHOUT_TAXES = 'priceWithoutTaxes',
  PRICE_WITH_TAXES = 'priceWithTaxes',
}

export const INVOICES_FILTERS_INITIAL_VALUES: TableFilters = {
  [InvoicesFiltersKeys.EXPLOITATION_UNIT_NAME]: '',
  [InvoicesFiltersKeys.IDENTIFIER]: '',
  [InvoicesFiltersKeys.INVOICED_AT]: { before: null, after: null },
  [InvoicesFiltersKeys.PRICE_WITHOUT_TAXES]: { min: null, max: null },
  [InvoicesFiltersKeys.PRICE_WITH_TAXES]: { min: null, max: null },
};
