import { Grow } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import { MouseEvent, ReactElement, RefObject } from 'react';

import { usePopperStyles } from './Popover.style';

interface Props {
  isOpen: boolean;
  close: () => void;
  anchorRef: RefObject<HTMLDivElement>;
  children: ReactElement;
  disablePortal?: boolean;
}

export const Popover = ({ isOpen, close, anchorRef, children, disablePortal = true }: Props) => {
  const classes = usePopperStyles();
  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    close();
  };

  return (
    <Popper
      className={classes.popper}
      open={isOpen}
      anchorEl={anchorRef.current}
      transition
      disablePortal={disablePortal}
      placement="bottom-end"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
          <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
        </Grow>
      )}
    </Popper>
  );
};
