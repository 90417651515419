import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const HamburgerMenuIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 16 16"
    style={{
      width: getSizeInPx(widthInPx ?? 30),
      height: getSizeInPx(heightInPx ?? 30),
    }}
  >
    <path
      d="M2.5 15H17.5V13.3333H2.5V15ZM2.5 10.8333H17.5V9.16667H2.5V10.8333ZM2.5 5V6.66667H17.5V5H2.5Z"
      fill={color ?? '#226C77'}
    />
    <path
      d="M2.5 15H17.5V13.3333H2.5V15ZM2.5 10.8333H17.5V9.16667H2.5V10.8333ZM2.5 5V6.66667H17.5V5H2.5Z"
      fill="black"
      fillOpacity="0.2"
    />
  </SvgIcon>
);
