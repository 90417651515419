import styled from 'styled-components';
import { colorPalette, typography } from 'stylesheet';

export const UserCardContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: '4px';
`;

export const UserNameContainer = styled.div`
  ${typography.title_xsmall}
  text-transform: capitalize;
`;
export const UserRoleContainer = styled.div`
  ${typography.p2_regular}
`;

export const UserMobileAvatar = styled.div`
  ${typography.p1_bold}
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  min-height: 35px;
  text-align: center;
  background-color: ${colorPalette.primary};
  color: ${colorPalette.white};
  border-radius: 50%;
`;
