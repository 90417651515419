import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core';

import { AdditionalIconButtonProps, useIconButtonStyle } from './IconButton.style';

type IconButtonProps = MuiIconButtonProps & AdditionalIconButtonProps;

export const IconButton = ({ displayStyle, children, ...props }: IconButtonProps) => {
  const classes = useIconButtonStyle({ displayStyle })();
  return (
    <MuiIconButton classes={classes} {...props}>
      {children}
    </MuiIconButton>
  );
};
