import { GDPRIcon } from 'assets/icons/GDPRIcon';
import { Button } from 'components/Button/Button';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { analytics } from 'services/analytics/analytics';
import {
  ANALYTICS_PROVIDER_ACTIONS,
  ANALYTICS_PROVIDER_CATEGORIES,
  ANALYTICS_PROVIDER_LABELS,
} from 'services/analytics/config';
import { CONTACT_MAIL_ADDRESS, PLATFORM_LEGAL_CORPORATION_LABEL } from 'services/api/constants';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import {
  ButtonContainer,
  GDPRSidePanelContainer,
  LinkPart,
  MessageContainer,
  MessagePart,
  Signature,
} from './GDPRSidePanel.style';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const GDPRSidePanel = ({ onClose, isOpen }: Props) => {
  const gdprSidePanelIntl = useIntlByKey('header.user-card.GDPR');

  const sendAnalyticsProviderGDPRDocumentClick = () => {
    analytics.event(
      ANALYTICS_PROVIDER_ACTIONS.GDPR_DOCUMENT_CLICK,
      ANALYTICS_PROVIDER_LABELS.CLICK,
      ANALYTICS_PROVIDER_CATEGORIES.GDPR,
    );
  };
  const sendAnalyticsProviderGDPREmailClick = () => {
    analytics.event(
      ANALYTICS_PROVIDER_ACTIONS.GDPR_EMAIL_CLICK,
      ANALYTICS_PROVIDER_LABELS.CLICK,
      ANALYTICS_PROVIDER_CATEGORIES.GDPR,
    );
  };

  return (
    <SidePanel
      title={gdprSidePanelIntl('title')}
      titleIcon={<GDPRIcon />}
      isOpen={isOpen}
      onClose={onClose}
      widthInPercent={50}
    >
      <GDPRSidePanelContainer>
        <MessageContainer>
          <MessagePart>
            {gdprSidePanelIntl('data-privacy-policy', {
              legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL,
            })}
          </MessagePart>
          <LinkPart>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PUBLIC_URL}/config/ppdp.pdf`}
              onClick={sendAnalyticsProviderGDPRDocumentClick}
            >
              {gdprSidePanelIntl('data-privacy-policy-document-name', {
                legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL,
              })}
            </a>
          </LinkPart>
          <MessagePart>{gdprSidePanelIntl('cookies-policy')}</MessagePart>
          <LinkPart>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PUBLIC_URL}/config/cookies.pdf`}
              onClick={sendAnalyticsProviderGDPRDocumentClick}
            >
              {gdprSidePanelIntl('cookies-policy-document-name')}
            </a>
          </LinkPart>
          <MessagePart>{gdprSidePanelIntl('legal-notice')}</MessagePart>
          <LinkPart>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PUBLIC_URL}/config/legal-notice.pdf`}
              onClick={sendAnalyticsProviderGDPRDocumentClick}
            >
              {gdprSidePanelIntl('legal-notice-document-name')}
            </a>
          </LinkPart>
          <MessagePart>{gdprSidePanelIntl('exercise-your-rights')}</MessagePart>
          <LinkPart>
            <a
              href={`mailto:${CONTACT_MAIL_ADDRESS}`}
              onClick={sendAnalyticsProviderGDPREmailClick}
            >
              {CONTACT_MAIL_ADDRESS}
            </a>
          </LinkPart>
          <Signature>
            {gdprSidePanelIntl('signature', {
              legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL,
            })}
          </Signature>
        </MessageContainer>
        <ButtonContainer>
          <Button displayStyle={'filledOrange'} onClick={onClose}>
            {gdprSidePanelIntl('close-button')}
          </Button>
        </ButtonContainer>
      </GDPRSidePanelContainer>
    </SidePanel>
  );
};
