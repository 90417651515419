export type ApiPageableResponse<ItemType> = {
  data: { attributes: ItemType }[];
  meta: {
    totalItems: number;
    itemsPerPage: number;
  };
};

export const HYDRA_MEMBER_PROPERTY = 'hydra:member';
export const HYDRA_TOTAL_ITEMS_PROPERTY = 'hydra:totalItems';
export const HYDRA_VIEW_PROPERTY = 'hydra:view';
export const HYDRA_NEXT_PROPERTY = 'hydra:next';
const HYDRA_MEMBER_ID = '@id';

export type ApiHydraPageableResponse<ItemType> = {
  [HYDRA_MEMBER_PROPERTY]: ItemType[];
  [HYDRA_TOTAL_ITEMS_PROPERTY]: number;
  [HYDRA_VIEW_PROPERTY]: {
    [HYDRA_MEMBER_ID]: string;
    [HYDRA_NEXT_PROPERTY]: string | undefined;
  };
};

export type HttpError = {
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

export type ApiActionResponse = {
  message: string;
  responseCode?: string;
};

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
