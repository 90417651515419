import {
  DESCRIPTION_ATTRIBUTE_FILE_FORMIK_KEY,
  DOCUMENT_PATH_FILE_FORMIK_KEY,
  TYPE_ID_ATTRIBUTE_FILE_FORMIK_KEY,
  UPLOADED_FILES_FORMIK_KEY,
} from 'services/utils/formik/keys';

import { PLATFORM_LEGAL_CORPORATION_ID } from '../constants';
import { ApiHydraPageableResponse, HYDRA_MEMBER_PROPERTY } from '../types';
import {
  ApiClientRequestInDetails,
  ApiClientRequestInList,
  ClientRequestDocumentForSidePanel,
  ClientRequestForTable,
  ClientRequestMessageForSidePanel,
  ClientRequestTableRowKeys,
  CreateClientRequestBody,
  CreateClientRequestForm,
  CreateClientRequestMessageBody,
  CreateClientRequestMessageForm,
  SpecificClientRequestDocumentTableRowKeys,
} from './types';

export const normalizeCreateClientRequestFormForRequestBody = (
  createClientRequestForm: CreateClientRequestForm,
  clientCompanyId: number,
  isBackofficeIssuer: boolean,
): CreateClientRequestBody => {
  return {
    clientCompanyId,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
    recipientId: createClientRequestForm.recipientId,
    title: createClientRequestForm.requestTitle,
    clientRequestMessage: {
      message: createClientRequestForm.requestDetails,
      documentsInformation: createClientRequestForm[UPLOADED_FILES_FORMIK_KEY].map(
        (fileInformation) => ({
          documentPath: fileInformation[DOCUMENT_PATH_FILE_FORMIK_KEY],
          description: fileInformation[DESCRIPTION_ATTRIBUTE_FILE_FORMIK_KEY],
          typeId: fileInformation[TYPE_ID_ATTRIBUTE_FILE_FORMIK_KEY],
        }),
      ),
    },

    isBackofficeIssuer,
  };
};

export const normalizeCreateClientRequestMessageFormForRequestBody = (
  createClientRequestMessageForm: CreateClientRequestMessageForm,
  clientCompanyId: number,
  clientRequestId: number,
): CreateClientRequestMessageBody => {
  return {
    clientCompanyId,
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
    clientRequestId: clientRequestId,
    clientRequestMessage: {
      message: createClientRequestMessageForm.requestMessage,
      documentsInformation: createClientRequestMessageForm[UPLOADED_FILES_FORMIK_KEY].map(
        (fileInformation) => ({
          documentPath: fileInformation[DOCUMENT_PATH_FILE_FORMIK_KEY],
          description: fileInformation[DESCRIPTION_ATTRIBUTE_FILE_FORMIK_KEY],
          typeId: fileInformation[TYPE_ID_ATTRIBUTE_FILE_FORMIK_KEY],
        }),
      ),
    },
  };
};

export const normalizeClientRequestsApiResponseForTable = (
  apiResponse: ApiHydraPageableResponse<ApiClientRequestInList>,
): ClientRequestForTable[] => {
  return apiResponse?.[HYDRA_MEMBER_PROPERTY]
    ? apiResponse[HYDRA_MEMBER_PROPERTY].map((clientRequest) => ({
        values: normalizeApiClientRequestForTable(clientRequest),
        isHighlighted: clientRequest.shouldRead,
      }))
    : [];
};

export const normalizeApiClientRequestForTable = (clientRequest: ApiClientRequestInList) => {
  return {
    [ClientRequestTableRowKeys.ID]: clientRequest.id,
    [ClientRequestTableRowKeys.REFERENCE]: clientRequest.reference,
    [ClientRequestTableRowKeys.TITLE]: clientRequest.title,
    [ClientRequestTableRowKeys.AUTHOR_FULL_NAME]:
      clientRequest.author?.userMetadata?.fullName ?? '-',
    [ClientRequestTableRowKeys.RECIPIENT_FULL_NAME]:
      clientRequest.recipient?.userMetadata?.fullName ?? '-',
    [ClientRequestTableRowKeys.CREATED_AT]: clientRequest.createdAt,
    [ClientRequestTableRowKeys.UPDATED_AT]: clientRequest.updatedAt,
    [ClientRequestTableRowKeys.IS_OPEN_CLIENT_REQUEST_BUTTON_AVAILABLE]: true,
  };
};

export const normalizeClientRequestApiResponseForSidePanel = (
  apiResponse: ApiClientRequestInDetails,
) => {
  const messages: ClientRequestMessageForSidePanel[] = [];
  const documents: ClientRequestDocumentForSidePanel[] = [];

  for (let k = 0; k < apiResponse.clientRequestMessages.length; k++) {
    const message = apiResponse.clientRequestMessages[k];
    messages.push({
      id: message.id,
      content: message.content,
      authorId: message.authorId,
      authorFullName: `${message.authorFirstName} ${message.authorLastName}`,
      documentsNumber: message.clientRequestDocuments.length,
      createdAt: message.createdAt,
      updatedAt: message.updatedAt,
    });
    for (let j = 0; j < message.clientRequestDocuments.length; j++) {
      const document = message.clientRequestDocuments[j];
      documents.push({
        values: {
          [SpecificClientRequestDocumentTableRowKeys.ID]: document.id,
          [SpecificClientRequestDocumentTableRowKeys.TYPE]: document.type.label,
          [SpecificClientRequestDocumentTableRowKeys.DESCRIPTION]: document.description,
          [SpecificClientRequestDocumentTableRowKeys.AUTHOR_FULL_NAME]: `${message.authorFirstName} ${message.authorLastName}`,
          [SpecificClientRequestDocumentTableRowKeys.CREATED_AT]: document.createdAt,
          [SpecificClientRequestDocumentTableRowKeys.PREVIEW_DOCUMENT_URL]: document.documentUrl,
          [SpecificClientRequestDocumentTableRowKeys.DOWNLOAD_DOCUMENT_URL]: document.documentUrl,
        },
      });
    }
  }

  //sort from newest to oldest
  messages.sort((message1, message2) => {
    const date1 = new Date(message1.createdAt);
    const date2 = new Date(message2.createdAt);
    return date2.getTime() - date1.getTime();
  });

  //sort from oldest to newest
  documents.sort((document1, document2) => {
    const date1 = new Date(document1.values.createdAt);
    const date2 = new Date(document2.values.createdAt);
    return date1.getTime() - date2.getTime();
  });

  return {
    id: apiResponse.id,
    reference: apiResponse.reference,
    title: apiResponse.title,
    messages: messages,
    documents: documents,
    clientCompany: apiResponse.clientCompany,
  };
};
