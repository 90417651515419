import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { OIDC_DEFAULT_SCOPES } from '@azure/msal-common';
import { ROUTES } from 'routing/routes';
import {
  AZURE_AD_B2C_CLIENT_ID,
  AZURE_AD_B2C_REDIRECTION_URI,
  PLATFORM_LEGAL_CORPORATION_ID,
} from 'services/api/constants';

const authAzureTenantName =
  window?.appConfig?.azureAdB2C?.tenantName ?? process.env.REACT_APP_AUTH_AZURE_TENANT_NAME;
const signInSignUpPolicy =
  window?.appConfig?.azureAdB2C?.signInSignUpPolicy ??
  process.env.REACT_APP_AUTH_AZURE_SIGN_IN_SIGN_UP_POLICY;
const backendClientId =
  window?.appConfig?.azureAdB2C?.backendClientId ??
  process.env.REACT_APP_AUTH_AZURE_BACKEND_CLIENT_ID;
const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AZURE_AD_B2C_CLIENT_ID,
    authority: `https://${authAzureTenantName}.b2clogin.com/${authAzureTenantName}.onmicrosoft.com/${signInSignUpPolicy}`,
    redirectUri: AZURE_AD_B2C_REDIRECTION_URI,
    postLogoutRedirectUri: AZURE_AD_B2C_REDIRECTION_URI + ROUTES.HOME,
    knownAuthorities: [`${authAzureTenantName}.b2clogin.com`],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

export const ACCESS_TOKEN_SCOPES = [
  `https://${authAzureTenantName}.onmicrosoft.com/${backendClientId}/Access`,
];

export const MSAL_EXTRA_QUERY_PARAMETERS = {
  extraQueryParameters: { sj: PLATFORM_LEGAL_CORPORATION_ID },
};

export const MSAL_REDIRECT_PARAMETERS = {
  scopes: OIDC_DEFAULT_SCOPES,
  ...MSAL_EXTRA_QUERY_PARAMETERS,
};
