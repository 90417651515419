import { useIsMobile } from 'breakpoints';
import { ApiResponseStatusGlobalDisplay } from 'components/ApiResponseStatusGlobalDisplay/ApiResponseStatusGlobalDisplay';
import { FunctionComponent, useState } from 'react';

import { Header } from './Header/Header';
import { LateralMenu } from './LateralMenu/LateralMenu';
import { MobileMenu } from './LateralMenu/MobileMenu';
import { BodyContainer, LayoutContainer, MainContainer } from './Layout.style';

export const Layout: FunctionComponent = (props) => {
  const isMobile = useIsMobile();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const onToggleDisplayMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <LayoutContainer>
      {isMobile ? (
        <MobileMenu isOpen={isMobileMenuOpen} onClose={onToggleDisplayMobileMenu} />
      ) : (
        <LateralMenu />
      )}
      <BodyContainer>
        <Header onToggleDisplayMobileMenu={onToggleDisplayMobileMenu} />
        <MainContainer isMobile={isMobile}>{props.children}</MainContainer>
        <ApiResponseStatusGlobalDisplay />
      </BodyContainer>
    </LayoutContainer>
  );
};
