import { TableFilters } from '../filtersKeys';

export enum InertWasteApplicationReferentsFiltersKeys {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}

export const INERT_WASTE_APPLICATION_REFERENTS_FILTERS_INITIAL_VALUES: TableFilters = {
  [InertWasteApplicationReferentsFiltersKeys.FIRST_NAME]: '',
  [InertWasteApplicationReferentsFiltersKeys.LAST_NAME]: '',
  [InertWasteApplicationReferentsFiltersKeys.EMAIL]: '',
};
