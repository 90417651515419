import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const ExchangeIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    style={{
      width: getSizeInPx(widthInPx ?? 20),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M17.5001 5.00008H15.8334V12.5001H5.00008V14.1667C5.00008 14.6251 5.37508 15.0001 5.83342 15.0001H15.0001L18.3334 18.3334V5.83342C18.3334 5.37508 17.9584 5.00008 17.5001 5.00008ZM14.1667 10.0001V2.50008C14.1667 2.04175 13.7917 1.66675 13.3334 1.66675H2.50008C2.04175 1.66675 1.66675 2.04175 1.66675 2.50008V14.1667L5.00008 10.8334H13.3334C13.7917 10.8334 14.1667 10.4584 14.1667 10.0001Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
