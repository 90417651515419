import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const WASTE_CODE_STORE_KEY = 'wasteCode';

const initialState: string = '';

const wasteCodeSlice = createSlice({
  name: WASTE_CODE_STORE_KEY,
  initialState,
  reducers: {
    setSelectedWasteCode: (state: string, action: PayloadAction<string>) => {
      state = action.payload ?? '';
      return state;
    },
  },
});

export const { setSelectedWasteCode } = wasteCodeSlice.actions;
export const wasteCodeReducer = wasteCodeSlice.reducer;
