import { datadogLogs } from '@datadog/browser-logs';

import {
  DATADOG_CLIENT_TOKEN,
  DATADOG_ENV,
  DATADOG_PROJECT_SERVICE,
  DATADOG_SITE,
} from '../api/constants';
import { LoggerInterface } from './LoggerInterface';

export class DatadogLogger implements LoggerInterface {
  init(): void {
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      forwardErrorsToLogs: true,
      service: DATADOG_PROJECT_SERVICE,
      sampleRate: 100,
      env: DATADOG_ENV,
    });
  }

  logError(message: string, context?: Record<string, unknown>): void {
    datadogLogs.logger.error(message, context);
  }

  logInfo(message: string, context?: Record<string, unknown>): void {
    datadogLogs.logger.log(message, context);
  }

  logWarn(message: string, context?: Record<string, unknown>): void {
    datadogLogs.logger.warn(message, context);
  }
}
