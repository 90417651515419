import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';
import { acquireAccessToken } from 'services/authentication/service';

import { HttpMethod } from './types';

const API_BASE_URL =
  (process.env.NODE_ENV === 'production' ? '' : process.env.REACT_APP_API_BASE_URL ?? '') + '/api';

const UNAUTHENTICATED_API_PREFIX = '/unauthenticated';
const SESSION_API_PREFIX = '/session';
const UNAUTHENTICATED_API_URL = API_BASE_URL + UNAUTHENTICATED_API_PREFIX;
const SESSION_API_URL = API_BASE_URL + SESSION_API_PREFIX;

class Client {
  baseUrl: string;
  client: AxiosInstance;
  isAuthenticated: boolean;
  withCredentials: boolean;

  constructor(baseUrl: string, isAuthenticated: boolean, withCredentials: boolean) {
    this.baseUrl = baseUrl;
    this.isAuthenticated = isAuthenticated;
    this.withCredentials = withCredentials;
    this.client = axios.create({ baseURL: this.baseUrl });
  }

  async request<DataType>(
    method: HttpMethod,
    endpoint: string,
    data: Record<string, unknown> | null = null,
    responseType?: ResponseType,
  ) {
    const { data: response } = await this.requestWithResponse<DataType>(
      method,
      endpoint,
      data,
      responseType,
    );
    return response;
  }

  async requestWithResponse<DataType>(
    method: HttpMethod,
    endpoint: string,
    data: Record<string, unknown> | null = null,
    responseType?: ResponseType,
  ) {
    const axiosConfig: AxiosRequestConfig = {
      method,
      url: endpoint,
      responseType,
      withCredentials: this.withCredentials,
    };

    if (this.isAuthenticated) {
      const accessToken = await acquireAccessToken();
      axiosConfig.headers = {
        Authorization: 'Bearer ' + accessToken,
      };
    }

    if (['POST', 'PUT', 'PATCH'].includes(method) && data) {
      axiosConfig.data = data;
    }

    return this.client.request<DataType>(axiosConfig);
  }
}

export const apiClient = new Client(API_BASE_URL, true, false);
export const sessionApiClient = new Client(SESSION_API_URL, false, true);
export const unauthenticatedApiClient = new Client(UNAUTHENTICATED_API_URL, false, false);
