import { lazy } from 'react';

export const HistoryPage = lazy(() =>
  import('./History/HistoryPage').then((module) => ({
    default: module.HistoryPage,
  })),
);

export const ClientCompaniesPage = lazy(() =>
  import('./ClientCompanies/ClientCompaniesPage').then((module) => ({
    default: module.ClientCompaniesPage,
  })),
);

export const ClientCompanyMainPage = lazy(() =>
  import('./ClientCompany/ClientCompanyMainPage').then((module) => ({
    default: module.ClientCompanyMainPage,
  })),
);

export const ClientCompanyHistoryPage = lazy(() =>
  import('./ClientCompany/ClientCompanyHistoryPage').then((module) => ({
    default: module.ClientCompanyHistoryPage,
  })),
);

export const UsersPage = lazy(() =>
  import('./Users/UsersPage').then((module) => ({
    default: module.UsersPage,
  })),
);

export const ClientCompanyUsersPage = lazy(() =>
  import('./ClientCompany/ClientCompanyUsersPage').then((module) => ({
    default: module.ClientCompanyUsersPage,
  })),
);

export const ClientCompanyBusinessReferentsPage = lazy(() =>
  import('./ClientCompany/ClientCompanyBusinessReferentsPage').then((module) => ({
    default: module.ClientCompanyBusinessReferentsPage,
  })),
);

export const SitesAndDocumentsPage = lazy(() =>
  import('./Sites/SitesAndDocumentsPage').then((module) => ({
    default: module.SitesAndDocumentsPage,
  })),
);

export const ClientRequestsPage = lazy(() =>
  import('./ClientRequests/ClientRequestsPage').then((module) => ({
    default: module.ClientRequestsPage,
  })),
);

export const InertWasteApplicationPage = lazy(() =>
  import('./InertWasteApplication/InertWasteApplicationPage').then((module) => ({
    default: module.InertWasteApplicationPage,
  })),
);

export const InvitationProcessEndPage = lazy(() =>
  import('./InvitationProcessEnd/InvitationProcessEndPage').then((module) => ({
    default: module.InvitationProcessEndPage,
  })),
);

export const SubscriptionRequestPage = lazy(() =>
  import('./SubscriptionRequest/SubscriptionRequestPage').then((module) => ({
    default: module.SubscriptionRequestPage,
  })),
);

export const SubscriptionRequestSuccessPage = lazy(() =>
  import('./SubscriptionRequest/SubscriptionRequestSuccessPage').then((module) => ({
    default: module.SubscriptionRequestSuccessPage,
  })),
);

export const BusinessAdminClientRequestsPage = lazy(() =>
  import('./ClientRequests/BusinessAdminClientRequests/BusinessAdminClientRequestsPage').then(
    (module) => ({
      default: module.BusinessAdminClientRequestsPage,
    }),
  ),
);

export const BusinessAdminInertWasteApplicationsPage = lazy(() =>
  import(
    './ClientRequests/BusinessAdminClientRequests/BusinessAdminInertWasteApplicationsPage'
  ).then((module) => ({
    default: module.BusinessAdminInertWasteApplicationsPage,
  })),
);

export const BusinessReferentsPage = lazy(() =>
  import('./BusinessReferents/BusinessReferentsPage').then((module) => ({
    default: module.BusinessReferentsPage,
  })),
);

export const ProducerFormPage = lazy(() =>
  import(
    './InertWasteApplication/InertWasteApplicationForPriorAcceptance/ProducerInterface/ProducerForm'
  ).then((module) => ({
    default: module.ProducerForm,
  })),
);

export const ExternalEvaluationPage = lazy(() =>
  import(
    './InertWasteApplication/InertWasteApplicationForPriorAcceptance/ExternalEvaluationInterface/ExternalEvaluationPage'
  ).then((module) => ({
    default: module.ExternalEvaluationPage,
  })),
);

export const ExternalUserEvaluationSuccessAsyncPage = lazy(() =>
  import(
    './InertWasteApplication/InertWasteApplicationForPriorAcceptance/Common/ExternalUserEvaluationSuccessAsync'
  ).then((module) => ({
    default: module.ExternalUserEvaluationSuccessAsync,
  })),
);

export const EvaluatorElectronicSignatureEnd = lazy(() =>
  import(
    './InertWasteApplication/InertWasteApplicationForPriorAcceptance/Common/ElectronicSignatureEnd/EvaluatorElectronicSignatureEnd'
  ).then((module) => ({
    default: module.EvaluatorElectronicSignatureEnd,
  })),
);

export const ExternalUserSynchronizationFailurePage = lazy(() =>
  import(
    './InertWasteApplication/InertWasteApplicationForPriorAcceptance/Common/ExternalUserZephyrSynchronizationFailure'
  ).then((module) => ({
    default: module.ExternalUserZephyrSynchronizationFailure,
  })),
);

export const ElectronicSignatureEndPage = lazy(() =>
  import(
    './InertWasteApplication/InertWasteApplicationForPriorAcceptance/Common/ElectronicSignatureEnd/ElectronicSignatureEnd'
  ).then((module) => ({
    default: module.ElectronicSignatureEnd,
  })),
);

export const ReferentsManagementPage = lazy(() =>
  import('./ReferentsManagement/ReferentsManagementPage').then((module) => ({
    default: module.ReferentsManagementPage,
  })),
);

export const NotFoundPage = lazy(() =>
  import('./NotFound/NotFoundPage').then((module) => ({
    default: module.NotFoundPage,
  })),
);

export const InertWasteApplicationGeneratorPage = lazy(() =>
  import('./InertWasteApplication/InertWasteApplicationGeneratorPage').then((module) => ({
    default: module.InertWasteApplicationGeneratorPage,
  })),
);

export const InertWasteApplicationGeneratorSuccessPage = lazy(() =>
  import('./InertWasteApplication/InertWasteApplicationGeneratorSuccessPage').then((module) => ({
    default: module.InertWasteApplicationGeneratorSuccessPage,
  })),
);
