import { DocumentInfoIcon } from 'assets/icons/DocumentInfoIcon';
import { Button } from 'components/Button/Button';
import { Dialog } from 'components/Dialog/Dialog';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { setIsError } from 'redux/ApiResponseStatusGlobalDisplay/slice';
import { useAppDispatch } from 'redux/hooks';
import { getSelectedPermission } from 'redux/User/selectors';
import { addCustomerAgreementToPermission } from 'redux/User/slice';
import { CUSTOMER_AGREEMENT_VERSION } from 'services/api/constants';
import { createCustomerAgreement } from 'services/api/customerAgreements/client';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';
import { logger } from 'services/logs/logger';

import { AgreementConditionsContainer, ButtonContainer } from './CustomerAgreementDialog.style';
interface Props {
  isOpen: boolean;
  closeCustomerAgreementDialog: () => void;
}

export const CustomerAgreementDialog = ({ isOpen, closeCustomerAgreementDialog }: Props) => {
  const createCustomerAgreementDialogIntl = useIntlByKey('home.customer-agreement-modal');
  const userPermission = useSelector(getSelectedPermission);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const onAgreementClick = async () => {
    if (userPermission) {
      setIsLoading(true);
      try {
        await createCustomerAgreement({ permissionId: userPermission.id });
        dispatch(
          addCustomerAgreementToPermission({
            permissionId: userPermission.id,
            version: CUSTOMER_AGREEMENT_VERSION,
          }),
        );
        closeCustomerAgreementDialog();
      } catch (error) {
        logger.logError('Error creating customer agreement', error);
        dispatch(setIsError(true));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      titleIcon={<DocumentInfoIcon />}
      title={createCustomerAgreementDialogIntl('title')}
      showCloseIcon={false}
      widthInPercent={70}
      heightInPercent={90}
    >
      <AgreementConditionsContainer>
        <iframe
          src={`${process.env.PUBLIC_URL}/config/cgu.pdf`}
          title="CGU Iframe"
          style={{ width: '100%', height: '100%', zoom: 1 }}
        />
        <ButtonContainer>
          <Button displayStyle={'filledOrange'} onClick={onAgreementClick} isLoading={isLoading}>
            <FormattedMessage id={createCustomerAgreementDialogIntl('accept-conditions')} />
          </Button>
        </ButtonContainer>
      </AgreementConditionsContainer>
    </Dialog>
  );
};
