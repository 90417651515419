import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const HomeIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    style={{
      width: getSizeInPx(widthInPx ?? 20),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M8.33342 17.0834V12.0834H11.6667V17.0834H15.8334V10.4167H18.3334L10.0001 2.91675L1.66675 10.4167H4.16675V17.0834H8.33342Z"
      fill={color ?? 'white'}
    />
  </SvgIcon>
);
